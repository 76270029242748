<template>
    <Form class="pump-config-model" ref="validateRef" :model="ProxyData" label-position="top" :rules="RuleValidate">
        <Row :gutter="25" type="flex" justify="start">
            <Col :xs="24" :md="6" :lg="6">
                <Button @click.native="addExtract" class="item-add-wallet" type="primary" long>{{ $t('lang.proxy.new_wallet') }}</Button>
                <div class="wallet-item-list">
                    <div @click="selectItem(index)" v-for="(item, index) in ProxyData.Extract" v-bind:key="index" v-bind:class="classObject(index)">
                        <div class="item-wallet-icon">
                            <Icon type="logo-bitcoin" :size="18" />
                        </div>
                        <div class="item-wallet-right">
                            <div class="item-wallet-right-title">
                                {{$t('lang.proxy.allot_wallet')}}-{{ index+1 }}
                            </div>
                            <div class="item-wallet-right-address">
                                {{ item.Wallet }}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col :xs="24" :md="18" :lg="18">
                <Row :gutter="25" type="flex" justify="start">
                    <Col :xs="24" :md="15" :lg="15">
                        <FormItem 
                            class="input-btn-group"
                            :label="$t('lang.proxy.fee_addr')"
                            :prop="'Extract.' + activeIndex + '.Pool'"
                            :rules="{
                                required: poolValidator, 
                                message: $t('lang.proxy.fee_addr_message'), 
                                trigger: 'blur'
                            }"
                        >
                            <Input v-model="ProxyData.Extract[activeIndex].Pool" :placeholder="$t('lang.proxy.fee_addr_des')"></Input>
                            <Button type="primary" @click="selectPreProxy">
                                <i class="el-icon-place"></i>
                                {{$t('lang.pump_config_p_1')}}
                            </Button>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :md="9" :lg="9">
                        <FormItem :label="$t('lang.proxy.fee_deal')">
                            <el-radio :disable="relievePool" v-model="ProxyData.Extract[activeIndex].Deal" label="TCP">TCP</el-radio>
                            <el-radio :disabled="relievePool" v-model="ProxyData.Extract[activeIndex].Deal" label="SSL">SSL</el-radio>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :md="15" :lg="15">
                        <FormItem 
                            class="input-btn-group"
                            :label="$t('lang.proxy.wallet_addr')"
                            :prop="'Extract.' + activeIndex + '.Wallet'"
                            :rules="{
                                required: walletValidator, 
                                message: $t('lang.proxy.wallet_perc'), 
                                trigger: 'blur'
                            }"
                        >
                            <Input v-model="ProxyData.Extract[activeIndex].Wallet" :placeholder="$t('lang.proxy.wallet_desc')"></Input>
                            <Button type="primary" @click="selectPreWallet">
                                <i class="el-icon-place"></i>
                                {{$t('lang.pump_config_p_1')}}
                            </Button>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :md="9" :lg="9">
                        <FormItem 
                            :label="$t('lang.proxy.fee_name')"
                            :prop="'Extract.' + activeIndex + '.DeviceName'"
                            :rules="{
                                required: nameValidator, 
                                message: $t('lang.proxy.wallet_perc'), 
                                trigger: 'blur'
                            }"
                        >
                            <Input 
                                v-model="ProxyData.Extract[activeIndex].DeviceName" 
                                :placeholder="$t('lang.proxy.fee_name_desc')"
                            ></Input>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                        <FormItem :label="$t('lang.proxy.fee_percent')">
                            <Slider v-model="ProxyData.Extract[activeIndex].Percent" :step="0.01" :min="0" :max="100" show-input></Slider>
                        </FormItem>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                        <FormItem>
                            <Button type="error" @click="deleteActive(activeIndex)" long>{{$t('lang.proxy.delete_wallet')}}-{{ activeIndex + 1 }}</Button>
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
        <select-preset-pool ref="select-preset-pool"></select-preset-pool>
        <select-preset-wallet ref="select-preset-wallet"></select-preset-wallet>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'

export default {
    data() {
        return {
            ProxyData: ProxyData.data,
            RuleValidate: ProxyData.ruleValidate,
            options: [
                // {
                //     label: '自定义矿池',
                //     options: [
                //         {
                //             label: '鱼池',
                //             value: 'www.baidu1.com'
                //         }
                //     ]
                // },
                // {
                //     label: '预置矿池',
                //     options: [
                //         {
                //             label: '猫池',
                //             value: 'www.baidu.com'
                //         }
                //     ]
                // }
            ],
            walletOptions: [
                // {
                //     label: '自定义钱包',
                //     options: [
                //         {
                //             label: '鱼池',
                //             value: 'www.baidu.com'
                //         }
                //     ]
                // }
            ],
            activeIndex: 0,
            selectValue: '',
            relievePool: false  // 关闭跨池提示
        }
    },
    computed: {
        classObject() {
            return (index) => {
                let res = {
                    "item-wallet": true,
                    "active": false
                }

                if (index == this.activeIndex) {
                    res.active = true
                }

                return res
            }
        },
        walletValidator() {
            if (!this.ProxyData.Extract[this.activeIndex].Percent) {
				return false
			} else {
                if (!this.ProxyData.Extract[this.activeIndex].Wallet) {
                    return true
                } else {
                    return false
                }
			}
        },
        poolValidator() {
            if (!this.ProxyData.Extract[this.activeIndex].Percent) {
				return false
			} else {
                if (!this.ProxyData.Extract[this.activeIndex].Pool) {
                    return true
                } else {
                    return false
                }
			}
        },
        nameValidator() {
            if (!this.ProxyData.Extract[this.activeIndex].Percent) {
				return false
			} else {
                if (!this.ProxyData.Extract[this.activeIndex].DeviceName) {
                    return true
                } else {
                    return false
                }
			}
        }
    },
    methods: {
        // extractPoolChange($e) {
        //     this.ProxyData.Extract[this.activeIndex].Pool = $e.target.value
        // },
        // extractWalletChange($e) {
        //     this.ProxyData.Extract[this.activeIndex].Wallet = $e.target.value
        // },
        resetActive() {
            this.activeIndex = 0
        },
        selectPreProxy() {
            let that = this;

            if (this.ProxyData.ProxyCoin) {
                this.$refs['select-preset-pool'].show(this.ProxyData.ProxyCoin, (row) => {
                    that.ProxyData.Extract[that.activeIndex].Deal = row.deal
                    that.ProxyData.Extract[that.activeIndex].Pool = row.address
                }) 
            } else {
                this.$Message.warning(this.$t('lang.pump_config_script_1'))
            }
        },
        selectPreWallet() {
            let that = this;

            if (this.ProxyData.ProxyCoin) {
                this.$refs['select-preset-wallet'].show((row) => {
                    that.ProxyData.Extract[that.activeIndex].Wallet = row.address
                }) 
            } else {
                this.$Message.warning(this.$t('lang.pump_config_script_1'))
            }
        },
        relieveEqual() {
            if (this.relievePool) {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$i18n.t('lang.proxy.alert_warning'),
                    text: this.$i18n.t('lang.proxy.alert_warning_text'),
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    preConfirm: (text) => {
                        if (
                            text == this.$t('lang.pump_config_script_2')
                            ||
                            text == "I understand that the different addresses of agent pool and commission pool may cause the equipment to fail to work normally"
                        ) {
                            this.relievePool = false
                        }
                    },
                })
            }
        },
        selectItem(index) {
            this.activeIndex = index
        },
        deleteActive(index) {
            if (this.ProxyData.Extract.length <= 1) {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$i18n.t('lang.proxy.alert_warning'),
                    text: this.$i18n.t('lang.proxy.alert_warning_text2')
                })
                // this.$Message.warning('至少保留一项钱包配置, 即便没有配置比例。');
            } else {
                if (index) {
                    this.ProxyData.Extract.splice(index, 1)
                } else {
                    this.ProxyData.Extract.splice(this.activeIndex, 1)
                }

                if (this.activeIndex != 0) {
                    this.activeIndex--
                }
            }
        },
        addExtract() {
            if (this.ProxyData.Extract.length > 9) {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$t('lang.pump_config_script_3'),
                    text: this.$t('lang.pump_config_script_4')
                })

                return false;
            }

            let item = ProxyData.createExtract(this.ProxyData.ProxyPool.Pool, this.ProxyData.ProxyPool.Deal);

            this.ProxyData.Extract.push(item)
            
            this.activeIndex = this.ProxyData.Extract.length - 1
        },
        checked() {
            this.ProxyData.Extract.forEach((item, index) => {
                this.ProxyData.Extract[index].Pool = this.$Func.dSpace(this.ProxyData.Extract[index].Pool)
                this.ProxyData.Extract[index].Wallet = this.$Func.dSpace(this.ProxyData.Extract[index].Wallet)
                this.ProxyData.Extract[index].DeviceName = this.$Func.dSpace(this.ProxyData.Extract[index].DeviceName)
            })

            return this.$refs['validateRef'].validate()
            // return new Promise((resolve, reject) => {
            //     this.$refs['validateRef'].validate().then(res => {
            //         if (allow) {
            //             resolve(true)
            //         } else {
            //             this.$Swal.fire({
            //                 icon: 'warning',
            //                 title: '抽水配置检查不通过',
            //                 text: `抽水钱包 ${i + 1} 配置检查不通过, 请修改后提交`
            //             })

            //             reject(false)
            //         }
            //     })
            // })
        }
    }
}
</script>

<style lang="scss">

.pump-config-model{
    .el-input.is-disabled .el-input__inner{
        cursor: pointer;
    }
}
</style>

<template>
    <div>
        <Modal
            class-name="safe-route-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="700"
            :title="$t('lang.set_safe_route')"
            :footer-hide="true"
        >
            <div v-loading="mloading">
                <p>{{$t('lang.set_safe_p_1')}}</p>
                <br>
                <p>{{$t('lang.set_safe_p_2')}}</p>
                <p>{{$t('lang.set_safe_p_3')}}</p>
                <br>
                <p>{{$t('lang.set_safe_p_4')}}</p>
                <br>
                <p>{{$t('lang.set_safe_p_5')}}</p>
                <br>
                <p class="tyellow">{{ $t('lang.set_safe_p_8') }}</p>
                <br>
                <Form label-position="left" :label-width="130">
                    <FormItem :label="$t('lang.set_safe_label_1')" style="margin: 20px 0;">
                        <a :href="preview_url" target="_blank">{{preview_url}}</a>
                    </FormItem>
                    <FormItem :label="$t('lang.set_safe_label_2')" style="margin: 20px 0;">
                        <el-input size="small" v-model="custom" :placeholder="$t('lang.set_safe_label_3')"></el-input>
                    </FormItem>
                    <FormItem label=" " style="margin: 20px 0;">
                        <p>{{$t('lang.set_safe_p_6')}}</p>
                    </FormItem>
                    <el-button :loading="bloading" type="success" size="small" style="width: 100%;" @click="submit">{{$t('lang.set_safe_p_7')}}</el-button>
                </Form>
            </div>
        </Modal>
    </div>
</template>
<script>

export default {
    data() {
        return {
            visable: false,
            custom: '',
            mloading: false,
            bloading: false
        }
    },
    computed:{
        preview_url() {
            let origin = window.location.origin;
            let res = origin + '/' + this.custom;

            if (this.custom) {
                res += '/'
            }
            return res
        }
    },
    methods:{
        show() {
            this.visable = true

            this.getRoute()
        },
        getRoute() {
            this.mloading = true

            this.axios({
                method: 'get',
                url: this.$CONFIG.API.getRoute
            }).then(res => {
                this.mloading = false

                if (res.status == 200) {
                    this.custom = this.serverToLocal(res.data)
                } else {
                    this.$Message.error(this.$t('lang.set_safe_route_script_3'))
                }
            })
        },
        serverToLocal(d) {
            let res = '';

            if (d) {
                res = d.substr(0, d.length - 1)
            }

            return res;
        },
        localToServe(d) {
            let res = '';

            if (d) {
                res = d + '/'
            }

            return res;
        },
        submit() {
            let custom = this.custom;

            if (custom.indexOf('//') > -1) {
                this.$Message.error(this.$t('lang.set_safe_route_script_4'));
                return
            }

            if (custom[custom.length - 1] == '/') {
                this.$Message.error(this.$t('lang.set_safe_route_script_5'));
                return
            }

            if (custom[0] == '/') {
                this.$Message.error(this.$t('lang.set_safe_route_script_6'));
                return
            }

            if (custom.indexOf('?') > -1) {
                this.$Message.error(this.$t('lang.set_safe_route_script_7'));
                return
            }

            this.$Swal.fire({
                title: this.$t('lang.set_safe_route_script_8'),
                html: this.$t('lang.set_safe_route_script_9').replace('{preview_url}', this.preview_url).replace('{preview_url}', this.preview_url),
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed){
                    this.bloading = true
                    
                    this.$notify({
                        title: this.$t('lang.set_safe_route_script_10'),
                        message: this.$t('lang.set_safe_route_script_11'),
                        type: 'success',
                        duration: 0
                    });

                    setTimeout(() => {
                        window.location.href = this.preview_url
                    }, 3000);

                    this.axios({
                        method: 'post',
                        url: this.$CONFIG.API.setRoute,
                        data: {
                            k: this.localToServe(this.custom)
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: $mdWidth){
    .safe-route-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .safe-route-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.safe-route-model{
    margin: 0 auto;
    width: 50%;
    max-width: 800px;
    overflow: hidden!important;
    overflow-y: auto!important;
}

</style>

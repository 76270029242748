import Vue from 'vue'
import App from './App.vue'


// 事件总线
Vue.prototype.$EventBus = new Vue()

// 生产环境不需要加载的列表 todo...
// import Mock from './mock/mock.js'

import Vuex from 'vuex'
import store from './store'
Vue.use(Vuex)

// i18n
import i18n from './locales/i18n'

let zh = window.localStorage.getItem('zh')
if (!zh) {
    zh = 'zh-EN'
}

store.dispatch("storage/updateLocal", {
    'zh': zh,
    'set_zh_hide_alert': true
})

// theme
let theme = window.localStorage.getItem('theme')
if (!theme) {
    theme = 'dark'
}
store.dispatch("storage/updateLocal", {
    'theme': theme
})

// md5
import md5 from 'md5';
Vue.prototype.$md5 = md5;

// homehashselect
let chart3select = window.localStorage.getItem('chart3select')
if (!chart3select) {
    chart3select = 'BTC'
}
store.dispatch("storage/updateLocal", {
    'chart3select': chart3select
})

// avesline
let defaultAves1 = window.localStorage.getItem('defaultAves1')
let defaultAves2 = window.localStorage.getItem('defaultAves2')

if (defaultAves1) {
    store.dispatch("storage/updateLocal", {
        'defaultAves1': JSON.parse(defaultAves1)
    })
} else {
    store.dispatch("storage/updateLocal", {
        'defaultAves1': 900
    })
}

if (defaultAves2) {
    store.dispatch("storage/updateLocal", {
        'defaultAves2': JSON.parse(defaultAves2)
    })
} else {
    store.dispatch("storage/updateLocal", {
        'defaultAves2': 86400
    })
}

// coincard配置
let hideDeProxyCoin = window.localStorage.getItem('hideDeProxyCoin');
let hideActiveCoin = window.localStorage.getItem('hideActiveCoin');

if (hideDeProxyCoin) {
    store.dispatch("storage/updateLocal", {
        'hideDeProxyCoin': JSON.parse(hideDeProxyCoin)
    })
} else {
    store.dispatch("storage/updateLocal", {
        'hideDeProxyCoin': false
    })
}

if (hideActiveCoin) {
    store.dispatch("storage/updateLocal", {
        'hideActiveCoin': JSON.parse(hideActiveCoin)
    })
} else {
    store.dispatch("storage/updateLocal", {
        'hideActiveCoin': false
    })
}

// loginfo
let user = window.localStorage.getItem('loginfo');
if (user) {
    store.dispatch("storage/updateLocal", {
        'loginfo': user
    })
}

// 设备列表页默认显示钱包列表还是设备列表
let walletPage = window.localStorage.getItem('walletPage');
if (!walletPage || walletPage == 'false') {
    walletPage = false
} else {
    walletPage = true
}
store.dispatch("storage/updateLocal", {
    'walletPage': walletPage
})

// 设备分布默认选择标签
let foundIndex = window.localStorage.getItem('foundIndex')
if (!foundIndex) {
    foundIndex = 0
}
foundIndex = JSON.parse(foundIndex)
store.dispatch("storage/updateLocal", {
    'foundIndex': foundIndex
})

// hidelistcard
let hideLeftCard = window.localStorage.getItem('hideLeftCard');
if (!hideLeftCard) {
    hideLeftCard = false
}

hideLeftCard = JSON.parse(hideLeftCard);
store.dispatch("storage/updateLocal", {
    'hideLeftCard': hideLeftCard
})

// 端口列表table相关
let listTab = window.localStorage.getItem('listTab');
let portListActiveSort = window.localStorage.getItem('portListActiveSort');
let portListPageSie = window.localStorage.getItem('portListPageSie');
let portListSortDir = window.localStorage.getItem('portListSortDir');

if (!listTab) {
    listTab = 0
}
if (!portListActiveSort) {
    portListActiveSort = 'port'
}
if (!portListPageSie) {
    portListPageSie = 30
}
if (!portListSortDir) {
    portListSortDir = 'ascending'
}
listTab = JSON.parse(listTab)
portListPageSie = JSON.parse(portListPageSie)
store.dispatch("storage/updateLocal", {
    listTab,
    portListActiveSort,
    portListPageSie,
    portListSortDir
})


// auth
import axios from '@/script/axiosBlocker.js'
Vue.prototype.axios = axios;

import CONFIG from './script/config.js'
Vue.prototype.$CONFIG = CONFIG.CONFIG;
Vue.prototype.$API = CONFIG.CONFIG.API;

import Func from './script/func.js'
Vue.prototype.$Func = Func.Func

import '@/style/SwalDark.scss'
import Swal from 'sweetalert2'
Vue.prototype.$Swal = Swal

import router from './router'

// terminal
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
Vue.prototype.$Terminal = Terminal
Vue.prototype.$FitAddon = FitAddon

// normalize
import 'normalize.css/normalize.css'
// 全局样式
import './style/global.scss'
// 替换一些三方插件原生样式
import './style/modify.scss'

// iview
// // import iView, { TabPane, Tabs } from 'iview';
import iView from 'iview';
import 'iview/dist/styles/iview.css';

Vue.use(iView);

iView.Message.config({
    duration: 10
})

// 用一些elementui组件, iview组件bug太jb多了
import { 
    Tooltip, 
    Select, 
    Option, 
    OptionGroup, 
    Loading,
    Radio,
    Table,
    TableColumn,
    Pagination,
    TabPane,
    Tabs,
    Link,
    Upload,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Notification,
    Tree,
    RadioGroup,
    RadioButton,
    Tag,
    Collapse,
    CollapseItem,
    Switch,
    Input,
    Button,
    // Dialog
} from 'element-ui';
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Radio.name, Radio);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Pagination.name, Pagination);
Vue.component(TabPane.name, TabPane)
Vue.component(Tabs.name, Tabs)
Vue.component(Link.name, Link)
Vue.component(Upload.name, Upload)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(Tree.name, Tree)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(RadioButton.name, RadioButton)
Vue.component(Tag.name, Tag)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.component(Switch.name, Switch)
Vue.component(Input.name, Input)
Vue.component(Button.name, Button)
// Vue.component(Dialog.name, Dialog)

Vue.prototype.$notify = Notification;

Vue.use(Loading);
Vue.use(Loading.directive); 

// echarts
import * as echarts from 'echarts/core'
import { 
    LineChart,
    BarChart,
    TreemapChart,
    GaugeChart,
    PieChart,
    LinesChart
} from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    PolarComponent,
    GraphicComponent,
    DataZoomComponent
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { ToolboxComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
    GraphicComponent,
    PolarComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    TreemapChart,
    BarChart,
    GaugeChart,
    PieChart,
    DataZoomComponent,
    LinesChart,
    ToolboxComponent
]);

Vue.prototype.$echarts = echarts


// 自己写复用的组件
// import ProxyModel from '@/components/ProxyModel.vue'
import AddPresetPool from '@/components/Model/AddPresetPool.vue'
import AddPresetWallet from '@/components/Model/AddPresetWallet.vue'
import SelectPresetPool from '@/components/Model/SelectPresetPool.vue'
import SelectPresetWallet from '@/components/Model/SelectPresetWallet.vue'
import WalletBan from '@/components/Model/WalletBan.vue'
import WalletAlert from '@/components/Model/WalletAlert.vue'
import SetPumpByWallet from '@/components/Model/SetPumpByWallet.vue'
import SetKencKey from '@/components/Model/SetKencKey.vue'

// Vue.component('ProxyModel', ProxyModel)
Vue.component('AddPresetPool', AddPresetPool)
Vue.component('AddPresetWallet', AddPresetWallet)
Vue.component('SelectPresetPool', SelectPresetPool)
Vue.component('SelectPresetWallet', SelectPresetWallet)
Vue.component('WalletBan', WalletBan)
Vue.component('WalletAlert', WalletAlert)
Vue.component('SetPumpByWallet', SetPumpByWallet)
Vue.component('SetKencKey', SetKencKey)

Vue.config.productionTip = false

// 这里设置title, 简单的反爬一下
// document.title = '😄'

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

console.log('\n')
console.log(`%c                     =##**#+:                     
                   :%*:   .+*==-                  
                  =@-      =++++:                 
                 =@:       .-=+#                  
       .::::..  .@-            %+   ...:..        
    =*++====++++#%--.       .::*%++========+=:    
  .#+.         :@--=+++=:-====-:##          :*=   
  =%           *%   .-**+*#+:   -@           -#   
  :%:          @+:=++-.    :+**-:@-        :-++   
   -%:        -%*=:     ..    :=*%*.      =====.  
    :#=    .=+*%.     -+++=.     *%*+:    =+==-   
     .**:.=+- =@   .=++++++=-:   *% :**-.=+:      
       =##+   =@   +*****+====   +%   -###        
      ++:.+*=.=%.  .=+###*+=-:   *% :++:.+#-      
    :*=    .*####=    =###*.     *%++:    :#+     
   -#:      .#####-    .:.    .-+#+.        **    
  .#:        .-%+:+*+-.    :=++-:@-          #+   
  =#           *%   .=##+*#+:   -@           =%   
  .*=.         :@--====-:-+++==:##          :#=   
    =++=======+=#%-:.       .:-*%+++++===++*+:    
       .......  .@-            %+   ..:::..       
                 =@:          ##                  
                  =@-        ##                   
                   :%*:   .=%+                    
                     =##**#*:                     `, 'color: #83d9ff;');

console.log('\n')
console.log('%cR%cU%cS%cT%cM%cI%cN%cE%cR%cS%cY%cS%cT%cE%cM', 'color: #FF3333; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #FF0000;', 'color: #FF5733; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #FF5733;', 'color: #FF9933; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #FF9933;', 'color: #FFCC33; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #FFCC33;', 'color: #FFFF33; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #FFFF33;', 'color: #99FF33; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #99FF33;', 'color: #33FF33; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #33FF33;', 'color: #33FF99; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #33FF99;', 'color: #33FFCC; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #33FFCC;', 'color: #33CCFF; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #33CCFF;', 'color: #3366FF; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #3366FF;', 'color: #6633FF; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #6633FF;', 'color: #9933FF; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #9933FF;', 'color: #CC33FF; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #CC33FF;', 'color: #FF33FF; font-size: 40px; font-weight: bold; text-shadow: 2px 2px 4px #FF33FF;')
console.log('\n')
console.log('👉👉👉 %cGithub：   https://github.com/EvilGenius-dot/RustMinerSystem', 'color: #83d9ff;');
console.log('👉👉👉 %cTelegram： https://t.me/rustkt                              ', 'color: #83d9ff;');
console.log('\n')
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{ref:"validateRef",attrs:{"model":_vm.ProxyData,"label-position":"left","label-width":100,"rules":_vm.RuleValidate}},[_c('Row',{attrs:{"gutter":25,"type":"flex","justify":"start"}},[_c('Col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.replace_name_p_1'),"prop":"ReplaceName.ByName","rules":{
                    required: _vm.byValidator, 
                    message: _vm.$t('lang.proxy.replace_err'), 
                    trigger: 'blur'
                }}},[_c('Input',{attrs:{"autosize":true,"type":"textarea","placeholder":_vm.$t('lang.replace_name_c_1')},model:{value:(_vm.ProxyData.ReplaceName.ByName),callback:function ($$v) {_vm.$set(_vm.ProxyData.ReplaceName, "ByName", $$v)},expression:"ProxyData.ReplaceName.ByName"}})],1)],1),_c('Col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.replace_name_p_2'),"prop":"ReplaceName.ToName","rules":{
                    required: _vm.toValidator, 
                    message: _vm.$t('lang.proxy.replace_err'), 
                    trigger: 'blur'
                }}},[_c('Input',{attrs:{"placeholder":_vm.$t('lang.replace_name_c_2')},model:{value:(_vm.ProxyData.ReplaceName.ToName),callback:function ($$v) {_vm.$set(_vm.ProxyData.ReplaceName, "ToName", $$v)},expression:"ProxyData.ReplaceName.ToName"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
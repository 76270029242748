import Swal from 'sweetalert2'
import { Message } from 'iview';
import i18n from '@/locales/i18n'

const Func = {
    nons() {
        Swal.fire({
            icon: 'warning',
            title: i18n.t('lang.nons_title'),
            text: i18n.t('lang.nons_text')
        })
    },
    // echarts resize防抖
    resizeEcharts(delay, echarts) {
        // 不用做防抖了, 如果防抖在浏览器大小发生变化的时候, 如果浏览器拉的比之前小
        // 在resize之前echarts的宽不会变, 导致flex布局出现问题
        return () => {
            echarts.resize()
        }
        // let time = null
        // let timer = null
        // let newTime = null

        // let task = () => {
        //     newTime = +new Date()

        //     if (newTime - time < delay) {
        //         timer = setTimeout(task, delay)
        //     } else {
        //         console.log('[--- resize防抖 ---]')
        //         echarts && echarts.resize()

        //         timer = null
        //     }
        //     time = newTime
        // }

        // return () => {
        //     time = +new Date()

        //     if (!timer) {
        //         timer = setTimeout(task, delay)
        //     }
        // }
    },
    // 获取coin的图标
    getCoinImage(path) {
        let img;
        try {
            img  = require(`@/assets/icon-${path.toLowerCase()}.png`);
        } catch {
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAdFJREFUWEftlT9IW1EUxn/fUyl0EaQI2uLUtUNxKHSSbh061sGtS8zTh+jcJZNZHAK+f2TqXKdCl06VgtAOdRB0LpRSiqsg2vBOeSEBkdDc9xKTJW+8957z/c53zrtXjPnTmPWZAEwcKORAZdsWvL8sex5zGPOZMBl/DM7TUJ/KDLQTQFu4RQVYFyz0FDK+A80kUrMIiBPAxpY9t4yjTuILjBPEicGl4BHwDFjK92W8iyO9cYVwAsiTbQRWx7iPsRfH+nlToFKxWW+GNYk6MIuxm0R66wLhDFCt2sM01a//JfW3bJmML4A3LZb293XeD8IZoF+i7r4f2DHwVLAZh4r7xd0FQO7Some8iiJ9HCmAH9h74HUuem+axUZDv+8UYGfH5i5bPMZ4AawJnnQEV5NQB/3E23+Ny6HbZ6qBrQg+314XfJNRjyJ9cM07DIAz4FRwNv+A3VpN167iQ3EgCVWqiC5kqeCbLRgbQLeCNNRhEct7zM0g4YPHlmpB+23YtL1MXKShaoNglALwA/vaeQFz7YMk1GpZiMIAve6AQQaxMEBeqR9YfgmtdKpuJqHWR+ZALpRfwVctXmYwwxSHaUM/RgpQVqxXXKkWTAAmDgzTgX/T/oghTADSSQAAAABJRU5ErkJggg=='
        }

        return img;
    },
    // 格式化时间戳
    formatTimestamp(stamp) {
        let date = new Date(stamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear();
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1);
        let D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate());
        let h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours());
        let m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes());
        let s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();

        return {
            Y,
            M,
            D,
            h,
            m,
            s
        }
    },
    // 范围随机数
    intRandomRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    selectFile(callback, format) {
        let el = document.createElement('input');
        el.type = 'file';

        el.addEventListener('change', e => {
            let name = el.files[0].name;
            let suffix = name.split('.')[1];

            if (el.value === '' || el.files.length < 1) {
                return false;
            }
            
            if (format && format.length) {
                let allow = false;

                format.forEach(item => {
                    if (item == suffix) {
                        allow = true
                    }
                })

                if (!allow) {
                    Swal.fire({
                        icon: 'error',
                        title: '文件格式错误',
                        text: `仅允许上传${format}格式的文件`
                    })

                    return false
                } else {
                    callback && callback(el, e)    
                }
            } else {
                callback && callback(el, e)
            }
        })
        
        el.click()
    },
    copyText(text, popText) {
        let el = document.createElement('input');
        document.body.appendChild(el);
        el.style.opacity = 0;
        el.type = 'text';
        el.value = text;
        el.select()
        if (document.execCommand('copy')) {
            // Message.success('复制成功！');
            Swal.fire({
                icon: 'success',
                title: '复制成功',
                text: popText ? popText : '已复制文本。'
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: '复制失败',
                text: '复制文本失败。'
            })
        }
        document.body.removeChild(el);
    },
    str2Base64 (str) {
        // 对编码的字符串转化base64
        let base64 = window.btoa(encodeURI(str));
        return base64;
    },
    transTime(timestamp, full) {
        let date = new Date(timestamp);
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

        let strDate;

        if (full) {
            strDate = Y + M + D + h + m + s;
        } else {
            strDate = h + m + s;
        }

        return strDate;
    },
    getTimeDiff(timestamp) {
        let pad = (num, size) => (Array(size).join("0") + num).slice(-size) 
        let diff = (+new Date() - timestamp) / 1000;
        let days = Math.floor(diff / (24 * 3600));
        let left = diff - days * 24 * 3600;
        let hour = Math.floor(left / 3600);
        left = left - hour * 3600;
        let min = Math.floor(left / 60);
        left = Math.floor(left - min * 60) % 60;

        let d = pad(days, 2);
        let h = pad(hour, 2);
        let m = pad(min, 2);
        let l = pad(left, 2);

        
        d == '00' ? (d = '') : (d = d + '天')
        h == '00' ? (h = '') : (h = h + '时')
        m == '00' ? (m = '') : (m = m + '分')
        l == '00' ? (l = '') : (l = l + '秒')

        return `${d}${h}${m}${l}`;
    },
    sortBy(attr,rev){
        //第二个参数没有传递 默认升序排列
        if (rev ==  undefined) {
            rev = 1;
        } else {
            rev = (rev) ? 1 : -1;
        }
        
        return function(a,b){
            a = a[attr];
            b = b[attr];
            if(a < b){
                return rev * -1;
            }
            if(a > b){
                return rev * 1;
            }
            return 0;
        }
    },
    exportConfig(config, noTransStr) {
        let el = document.createElement('a');
        document.body.appendChild(el);

        let jsonStr = noTransStr ? config : JSON.stringify(config);
        let blob = new Blob([jsonStr]); //  创建 blob 对象
        el.href = URL.createObjectURL(blob);
        el.download = "config.json";
        el.click();

        document.body.removeChild(el);
    },
    transHash(hash, alg, rr) {
        // rr为0，计算方式为btc类似协议
        // rr为1, 摇骰子抽水方式并且重复份额不计算id方式
        let res = hash;

        if (alg) {
            alg = alg.toLowerCase()
        }

        if (!res) {
            res = 0;
        } else {
            if (typeof(res) == 'string' && res.indexOf('"') > -1) {
                res = JSON.parse(res)
            }
            res = Number(res);
        }

        if (isNaN(res)) {
            res = 0
        }

        if (alg == 'ethhash' || alg == 'ethhash2d') {
            // console.log(alg)
            // console.log(rr)
            if (rr === 0) {
                let t = res * Math.pow(2, 32) / 1000000000000
                let p = t / 1000;
                let g = 1000 * t;
                let m = 1000 * g;
                let k = 1000 * m;

                if (p > 1) {
                    res = p.toFixed(2) + 'P'
                } else if (t > 1) {
                    res = t.toFixed(2) + 'T'
                } else if (g > 1) {
                    res = g.toFixed(2) + 'G'
                } else if (m > 1) {
                    res = m.toFixed(2) + 'M'
                } else if (k > 1) {
                    res = k.toFixed(2) + 'K'
                } else {
                    res = k.toFixed(2) + 'K'
                }

                return res
            } else {
                let m = res / 1000000;
                let g = m / 1000;
                let t = g / 1000;

                if (t > 1) {
                    res = t.toFixed(2) + 'T'
                } else if (g > 1) {
                    res = g.toFixed(2) + 'G'
                } else if (m > 1) {
                    res = m.toFixed(2) + 'M'
                } else {
                    res = m.toFixed(2) + 'M'
                }

                return res
            }
        } else if (alg == 'eth1hash') {
            let m = res / 1000000;
            let k = m * 1000;
            let g = m / 1000;
            let t = g / 1000;

            if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'octopus') {
            let m = res / 1000000;
            let g = m / 1000;
            let t = g / 1000;

            if (t > 1) {6
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else {
                res = m.toFixed(2) + 'M'
            }

            return res
        } else if (alg == 'sha256' || alg == 'sha512256d' || alg == 'nexapow') {
            let t = res * Math.pow(2,32) / 1000000000000
            let p = t / 1000;
            let g = 1000 * t;
            let m = 1000 * g;
            let k = 1000 * m;

            if (p > 1) {
                res = p.toFixed(2) + 'P'
            } else if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'cuckatoo32') {
            let g = res * Math.pow(2, 32) / 100000000;
            let k = g / 1000;

            if (k > 1) {
                res = k.toFixed(2) + 'KGps'
            } else {
                res = g.toFixed(2) + 'Gps'
            }

            return res
            
        } else if (alg == 'kheavyhash' || alg == 'karlsenhash') {
            // let m = res * Math.pow(2, 12);
            let m = res * Math.pow(2, 12.06845);
            let g = m / 1000;
            let t = g / 1000;

            if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else {
                res = m.toFixed(2) + 'M'
            }

            return res
        } else if (alg == 'scrypt') {
            let m = res * 64 / 1000;
            let g = m / 1000;
            // let k = 1000 / m;
            let k = 0;

            if (m > 0) {
                k = 1000 / m;
            }

            if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'dynexsolve') {
            let kh = res / 1000;

            // if (kh) {
            //     res = kh.toFixed(2) + 'Kh'
            // } else {
            //     res = '0Kh'
            // }

            return kh.toFixed(2) + 'Kh';
        } else if (alg == 'blake2s' || alg == 'eaglesong') {
            // 因为eth双协议都转成了eth算力, 影响到了kda, 所以kda难度转换之后会转成btc的难度, 按照btc处理
            let t = res * Math.pow(2,32) / 1000000000000
            let p = t / 1000;
            let g = 1000 * t;
            let m = 1000 * g;
            let k = 1000 * m;

            if (p > 1) {
                res = p.toFixed(2) + 'P'
            } else if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'lbry') {
            // 经验值
            let t = res / 50000
            let p = t / 1000;
            let g = 1000 * t;
            let m = 1000 * g;
            let k = 1000 * m;

            if (p > 1) {
                res = p.toFixed(2) + 'P'
            } else if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'blake2b') {
            let t = res / 160
            let g = 1000 * t;
            let m = 1000 * g;
            let k = 1000 * m;

            if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'equihash') {
            let bits = res * 480.9995403289804;
            let k = bits / 150000 / 4;
            let m = k / 1024;
            let g = m / 1024;

            if (g > 1) {
                res = g.toFixed(2) + 'GSol'
            } else if (m > 1) {
                res = m.toFixed(2) + 'MSol'
            } else if (k > 1) {
                res = k.toFixed(2) + 'KSol'
            } else {
                res = k.toFixed(2) + 'KSol'
            }


            return res
        } else if (alg == 'x11') {
            let t = res * Math.pow(2, 32) / 1000000000000
            let p = t / 1000;
            let g = 1000 * t;
            let m = 1000 * g;
            let k = 1000 * m;

            if (p > 1) {
                res = p.toFixed(2) + 'P'
            } else if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'progpow') {
            let m = res / 1000000;
            let g = m / 1000;
            let t = g / 1000;

            if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else {
                res = m.toFixed(2) + 'M'
            }

            return res
        } else if (alg == 'blake3') {
            let g = res / 62499999.99999999
            let t = g / 1000;
            let p = t / 1000;
            let m = g * 1000;
            let k = m * 1000;

            if (p > 1) {
                res = p.toFixed(2) + 'P'
            } else if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'blake3s') {
            let m = res / 1000000;
            let g = m / 1000;
            let t = g / 1000;

            if (t >= 1) {
                res = t.toFixed(2) + 'T'
            } else if (g >= 1) {
                res = g.toFixed(2) + 'G'
            } else {
                res = m.toFixed(2) + 'M'
            }

            return res

        } else if (alg == 'randomx') {
            let k = res / 1000;
            let m = k / 1000;
            
            if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'ghostrider') {
            let h = res * 60000;
            let k = h / 1000;

            if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else if (h > 1) {
                res = h.toFixed(2) + 'H'
            } else {
                res = h.toFixed(2) + 'H'
            }

            return res
        } else if (alg == 'kawpow') {
            let m = res / 1000000;
            let g = m / 1000;
            let t = g / 1000;

            if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else {
                res = m.toFixed(2) + 'M'
            }

            return res
        } else if (alg == 'kawpow2') {
            let t = res * Math.pow(2,32) / 1000000000000
            let p = t / 1000;
            let g = 1000 * t;
            let m = 1000 * g;
            let k = 1000 * m;

            if (p > 1) {
                res = p.toFixed(2) + 'P'
            } else if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = k.toFixed(2) + 'K'
            } else {
                res = k.toFixed(2) + 'K'
            }

            return res
        } else if (alg == 'autoykos2') {
            let g = res / 1000000000;
            let m = g * 1000;
            let k = m * 1000;
            let t = g / 1000;

            if (t > 1) {
                res = t.toFixed(2) + 'T'
            } else if (g > 1) {
                res = g.toFixed(2) + 'G'
            } else if (m > 1) {
                res = m.toFixed(2) + 'M'
            } else if (k > 1) {
                res = m.toFixed(2) + 'K'
            } else {
                res = m.toFixed(2) + 'K'
            }

            return res
        } else {
            return res.toFixed(2)
        }
    },
    transNumber(num) {
        let res = num;

        let k = res / 1000;

        if (k > 1) {
            return k.toFixed(2) + 'k'
        } else {
            return res
        }
    },
    // 删除掉字符串中的空格
    dSpace(str) {
        if (str) {
            return str.replace(/\s*/g,"");
        } else {
            return str
        }
    },
    // 删掉文本中多余的换行以及收尾空白和换行
    cleanText(str) {
        str = str.trim();

        // 使用正则表达式替换两个或更多连续的换行符为一个换行符
        str = str.replace(/\n{2,}/g, '\n');

        return str;
    }
}

export default{
    Func
}
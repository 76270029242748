<template>
    <div class="add-preset-pool">
        <Modal
            class-name="add-preset-pool-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.add_perset_pool_title')"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p style="margin-bottom: 20px;">{{$t('lang.add_perset_pool_desc')}}</p>
                <Form :label-width="120">
                    <FormItem :label="$t('lang.add_perset_pool_select_coin')">
                        <el-select  @change="activeCoinChange" size="small" :placeholder="$t('lang.pleaseSelect')" v-model="selectCoin">
                            <el-option v-for="(val, key, index) in getSortCurrency" :key="index" :value="val.name" :label="val.name">
                                <div class="add-preset-pool-select-coin-item">
                                    <img :src="$Func.getCoinImage(`${(val.name).toLowerCase()}`)" />
                                    <span>{{ transName(val.name) }}</span>
                                </div>
                            </el-option>
                        </el-select>
                    </FormItem>
                    <FormItem :label="$t('lang.add_perset_pool_input_addr_label')">
                        <el-input v-model="selectName" size="small" :placeholder="$t('lang.add_perset_pool_input_addr_label_desc')"></el-input>
                    </FormItem>
                    <FormItem :label="$t('lang.add_perset_pool_input_addr')">
                        <el-input v-model="selectAddress" size="small" :placeholder="$t('lang.add_perset_pool_input_addr_desc')"></el-input>
                    </FormItem>
                    <FormItem :label="$t('lang.add_perset_pool_input_deal')">
                        <el-radio v-model="selectDeal" label="TCP">TCP</el-radio>
                        <el-radio v-model="selectDeal" label="SSL">SSL</el-radio>
                    </FormItem>
                    <FormItem label=" ">
                        <Button type="primary" @click="addPool">{{$t('lang.add_perset_pool_fast_button')}}</Button>
                    </FormItem>
                </Form>
                <p style="margin-bottom: 20px;">{{$t('lang.add_perset_pool_pool_preview')}}</p>
                <el-tabs type="card" class="device-list-tab">
                    <el-tab-pane class="device-list-tab-panel" :label="$t('lang.add_perset_pool_custom_pool')">
                        <el-table
                            :data="presetPool"
                            style="width: 100%"
                            :empty-text="`${$t('lang.add_perset_pool_scripe_1').replace('{selectCoin}', selectCoin)}`"
                        >
                            <el-table-column
                                prop="name"
                                :label="$t('lang.add_perset_pool_name')"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <img class="name-coin" :src="$Func.getCoinImage(`${(scope.row.coin).toLowerCase()}`)" />
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                :label="$t('lang.add_perset_pool_addr')"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deal"
                                :label="$t('lang.add_perset_pool_deal')"
                            >
                            </el-table-column>
                            <el-table-column :label="$t('lang.add_perset_pool_opt')">
                                <template slot-scope="scope">
                                    <el-link type="warning" @click="deleteItem(scope)">
                                        {{$t('lang.add_perset_pool_delete')}}
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane class="device-list-tab-panel" :label="$t('lang.add_perset_pool_in_pool')">
                        <el-table
                            :data="poolInfo"
                            style="width: 100%"
                            :empty-text="`${$t('lang.add_perset_pool_in_scripe_1').replace('{selectCoin}', selectCoin)}`"
                        >
                            <el-table-column
                                prop="name"
                                :label="$t('lang.add_perset_pool_name')"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <img class="name-coin" :src="$Func.getCoinImage(`${(scope.row.coin).toLowerCase()}`)" />
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                :label="$t('lang.add_perset_pool_addr')"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deal"
                                :label="$t('lang.add_perset_pool_deal')"
                            >
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Modal>
    </div>
</template>
<script>

import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            selectCoin: 'BTC',
            selectName: '',
            selectAddress: '',
            selectDeal: 'TCP',
            endPoint: {},
            presetPool: [],
            serverPool: [],
            poolInfo: []
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency'
        })
    },
    mounted() {
        
    },
    methods: {
        show() {
            this.visable = true

            this.getEndPoint()

            this.getPoolInfo()
        },
        getPoolInfo() {
            let that = this;

            this.axios({
                method: 'get',
                url: this.$API.getPoolInfo.replace('{id}', 1)
            }).then(res => {
                if (res && res.status == 200) {
                    if (res.data && res.data.length) {
                        that.serverPool = res.data

                        this.renderList()
                    }
                } else {
                    this.$Message.error(this.$t('lang.add_perset_pool_script_1'))
                }
            })
        },
        hide() {
            this.visable = false
        },
        addPool() {
            let that = this;

            if (!this.selectCoin || !this.selectName || !this.selectAddress || !this.selectDeal) {
                this.$Message.warning(this.$t('lang.add_perset_pool_script_2'))
                return
            }

            let item = {
                coin: this.selectCoin,
                name: this.selectName,
                address: this.selectAddress,
                deal: this.selectDeal
            }

            if (!this.endPoint.customPool) {
                this.endPoint.customPool = [item]
            } else {
                this.endPoint.customPool.push(item)
            }

            this.setEndPoint()
        },
        getEndPoint() {
            let that = this;
            this.loading = true;

            this.axios({
                method: 'get',
                url: that.$API.getEndPoint
            }).then(res => {
                if (res.status == 200) {
                    that.endPoint = res.data

                    this.renderList();
                } else {
                    that.$Message.error(this.$t('lang.add_perset_pool_script_3'));
                }

                this.loading = false
            })
        },
        setEndPoint() {
            let that = this;
            this.loading = true;

            let requestData = this.endPoint;
            
            this.axios({
                method: 'post',
                url: that.$API.setEndPoint,
                data: requestData
            }).then(res => {
                if (res.status == 200) {
                    that.$Message.success(this.$t('lang.add_perset_pool_script_4'));
                    that.getEndPoint()
                } else {
                    that.$Message.error(this.$t('lang.add_perset_pool_script_5'));

                    this.loading = false
                }
            })
        },
        renderList() {
            let customPool = this.endPoint.customPool;
            let selectCoin = this.selectCoin;
            let serverPool = this.serverPool;

            if (customPool) {
                let presetPool = []

                for (let i = 0; i < customPool.length; i++) {
                    let item = customPool[i];

                    if (item.coin == selectCoin) {
                        presetPool.push(item)
                    }
                }

                this.presetPool = presetPool
            }

            if (serverPool) {
                let poolInfo = [];

                for (let i = 0; i < serverPool.length; i++) {
                    let item = serverPool[i];

                    if (item.brand == selectCoin) {
                        let insert = {
                            coin: item.brand,
                            name: item.content,
                            address: item.url
                        }

                        if (item.protocol == 0) {
                            insert.deal = 'TCP'
                        } else if (item.protocol == 1) {
                            insert.deal = 'SSL'
                        }

                        poolInfo.push(insert)
                    }
                }

                this.poolInfo = poolInfo
            }
        },
        activeCoinChange() {
            this.renderList()

            this.getPoolInfo()
        },
        deleteItem(scope) {
            let index = scope.$index;

            this.presetPool.splice(index, 1);

            this.endPoint.customPool = this.presetPool;

            this.setEndPoint()
        },
        transName(v) {
            let res = v;

            if (v == 'NGINX') {
                res = this.$t('lang.add_perset_pool_script_6')
            } else if (v == 'KAS(ks3)') {
                res = 'KAS(ks3, ks3l, ks3m)'
            } else if (v == 'KASPA') {
                res = 'KAS(GPU)'
            }

            return res
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .add-preset-pool-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .add-preset-pool-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.add-preset-pool-model{
    margin: 0 auto;
    width: 50%;
    max-width: 700px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .el-tabs__item{
        font-size: 12px;
    }

    .pre-name{
        display: flex;
        align-items: center;

        .name-coin{
            height: 20px;
            display: block;
            margin-right: 5px;
        }
    }
}

.add-preset-pool-select-coin-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
        display: block;
        // width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    span{
        font-size: 14px;
    }
    .engine{
        font-size: 12px;
        flex: 1;
        text-align: right;
    }
}
</style>

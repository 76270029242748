<template>
    <Form @submit.native.prevent class="default-config-class" ref="validateRef" :model="ProxyData" label-position="top" :rules="RuleValidate">
        <Row :gutter="25" type="flex" justify="start">
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.port_num')" prop="Port">
                    <InputNumber v-model="ProxyData.Port" empty placeholder="1-65534" :max="65534" :min="1" long style="width: 100%;"></InputNumber>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.proxy_coin')" prop="ProxyCoin" class="port-coin-select">
                    <Select filterable transfer @on-change="activeCoinChange" v-model="ProxyData.ProxyCoin" style="width:100%;" :placeholder="$t('lang.pleaseSelect')">
                        <Option v-for="(val, key, index) in getSortCurrency" :key="index" :value="val.name" :label="val.name">
                            <div class="select-coin-item fixed-select-coin-item">
                                <img :src="$Func.getCoinImage(`${(val.name).toLowerCase()}`)" />
                                <span>{{ transName(val.name) }}</span>
                            </div>
                        </Option>
                    </Select>
                    <div v-if="ProxyData.ProxyCoin == 'BTC' || ProxyData.ProxyCoin == 'BCH' || ProxyData.ProxyCoin == 'LTC'">
                        <el-tooltip class="item" effect="dark" :content="$t('lang.default_config_content_1')" placement="bottom">
                            <p class="s-tit">
                                <i class="question el-icon-question"></i>
                                {{$t('lang.default_config_p_1')}}
                            </p>
                        </el-tooltip>
                        <Radio-group>
                            <el-radio v-model="ProxyData.setting.op" :label="1">{{$t('lang.default_config_p_2')}}</el-radio>
                            <el-radio v-model="ProxyData.setting.op" :label="0">{{$t('lang.default_config_p_3')}}</el-radio>
                        </Radio-group>
                    </div>
                    <div v-if="getCurrency[ProxyData.ProxyCoin] && (getCurrency[ProxyData.ProxyCoin].category == 'dynexsolve')">
                        <p class="mallob-title s-tit">{{$t('lang.default_config_p_4')}}</p>
                        <p class="mallob-p" @click="mobTips"> 
                            <i class="el-icon-success success" v-if="getMob.mobStatus == 1"></i>
                            <i class="el-icon-error error" v-if="getMob.mobStatus != 1"></i>
                            <span>{{getMob.mobURL}}</span>
                        </p>
                    </div>
                    <div v-if="getCurrency[ProxyData.ProxyCoin] && (getCurrency[ProxyData.ProxyCoin].category == 'kheavyhash' || getCurrency[ProxyData.ProxyCoin].category == 'ethhash' || getCurrency[ProxyData.ProxyCoin].category == 'ethhash2d')">
                        <el-tooltip class="item" effect="dark" :content="$t('lang.default_config_content_2')" placement="bottom">
                            <p class="s-tit">
                                <i class="question el-icon-question"></i>
                                {{$t('lang.default_config_p_5')}}
                            </p>
                        </el-tooltip>
                        <Radio-group>
                            <el-radio v-model="ProxyData.setting.pth" :label="0">{{$t('lang.default_config_p_2')}}</el-radio>
                            <el-radio v-model="ProxyData.setting.pth" :label="1">{{$t('lang.default_config_p_3')}}</el-radio>
                        </Radio-group>
                    </div>
                    <!-- <div v-if="getCurrency[ProxyData.ProxyCoin] && (getCurrency[ProxyData.ProxyCoin].category == 'ethhash' || getCurrency[ProxyData.ProxyCoin].category == 'ethhash2d')">
                        <el-tooltip class="item" effect="dark" content="优化矿机的并发连接, 一些机型需要关闭, 如gtv66。" placement="bottom">
                            <p class="s-tit">
                                <i class="question el-icon-question"></i>
                                并发优化:
                            </p>
                        </el-tooltip>
                        <Radio-group>
                            <el-radio v-model="ProxyData.setting.cut" :label="0">开启</el-radio>
                            <el-radio v-model="ProxyData.setting.cut" :label="1">关闭</el-radio>
                        </Radio-group>
                    </div> -->
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.max_conn')">
                    <InputNumber v-model="ProxyData.MaxConnect" :value="0" :placeholder="$t('lang.default_config_place_1')" :max="65535" :min="0" long style="width: 100%;"></InputNumber>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.remark')">
                    <Input v-model="ProxyData.Info" :placeholder="$t('lang.proxy.des_remark')"></Input>
                </FormItem>
            </Col>
            <Col :xs="24" :md="12" :lg="12">
                <FormItem class="input-btn-group" :label="$t('lang.proxy.proxy_addr')" prop="ProxyPool.Pool">
                    <Input v-model="ProxyData.ProxyPool.Pool" @on-change="poolURLChange" :placeholder="$t('lang.proxy.proxy_addr_des')"></Input>
                    <Button type="primary" @click="selectPreProxy('proxy')">
                        <i class="el-icon-place"></i>
                        {{$t('lang.default_config_p_6')}}
                    </Button>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.proxy_addr_deal')">
                    <el-radio @change="dealChange" v-model="ProxyData.ProxyPool.Deal" label="TCP">TCP</el-radio>
                    <el-radio @change="dealChange" v-model="ProxyData.ProxyPool.Deal" label="SSL">SSL</el-radio>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.conn_test')">
                    <!-- 请输入或选择一个矿池地址 -->
                    <beat-ani v-if="pStatus == 'ping'"></beat-ani>
                    <span class="info" v-show="pStatus == 'wait'">
                        {{$t('lang.default_config_p_7')}}
                    </span>
                    <span v-show="pStatus == 'ok'" class="success">
                        <Icon type="md-checkmark-circle-outline" />{{$t('lang.proxy.conn_test_ok')}}
                    </span>
                    <span v-show="pStatus == 'error'" class="error">
                        <Icon type="md-close" />{{$t('lang.proxy.conn_test_no')}}
                    </span>
                </FormItem>
            </Col>
            <Col :xs="24" :md="12" :lg="12">
                <FormItem class="input-btn-group" :label="$t('lang.proxy.stand_pool')">
                    <Input v-model="ProxyData.SparePool.Pool" @on-change="sPoolURLChange" :placeholder="$t('lang.proxy.stand_pool_des')"></Input>
                    <Button type="primary" @click="selectPreProxy('spare')">
                        <i class="el-icon-place"></i>
                        {{$t('lang.default_config_p_6')}}
                    </Button>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.stand_pool_deal')">
                    <RadioGroup size="large">
                        <el-radio @change="sDealChange" v-model="ProxyData.SparePool.Deal" label="TCP">TCP</el-radio>
                        <el-radio @change="sDealChange" v-model="ProxyData.SparePool.Deal" label="SSL">SSL</el-radio>
                    </RadioGroup>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.conn_test')">
                    <!-- 请输入或选择一个矿池地址 -->
                    <beat-ani v-if="spStatus == 'ping'"></beat-ani>
                    <span class="info" v-show="spStatus == 'wait'">
                        {{$t('lang.default_config_p_7')}}
                    </span>
                    <span v-show="spStatus == 'ok'" class="success">
                        <Icon type="md-checkmark-circle-outline" />{{$t('lang.proxy.conn_test_ok')}}
                    </span>
                    <span v-show="spStatus == 'error'" class="error">
                        <Icon type="md-close" />{{$t('lang.proxy.conn_test_no')}}
                    </span>
                </FormItem>
            </Col>
        </Row>
        <select-preset-pool ref="select-preset-pool"></select-preset-pool>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'
import BeatAni from '@/components/BeatAni.vue'
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            pStatus: 'wait', // 'wait, ping, error, ok'
            spStatus: 'wait',
            ProxyData: ProxyData.data,
            RuleValidate: ProxyData.ruleValidate,
            options: [
                // {
                //     label: '自定义矿池',
                //     options: [
                //         {
                //             label: '鱼池',
                //             value: 'www.baidu1.com'
                //         }
                //     ]
                // },
                // {
                //     label: '预置矿池',
                //     options: [
                //         {
                //             label: '猫池',
                //             value: 'www.baidu.com'
                //         }
                //     ]
                // }
            ]
        }
    },
    components: {
        BeatAni
    },
    computed:{
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency',
            getMob: 'getMob'
        })
        // showE9() {
        //     let res = false;
        //     let proxyCoin = this.ProxyData.proxyCoin;
        //     let category = this.getCurrency[proxyCoin];

        //     if (category == 'ethhash' || category == 'ethhash2d') {
        //         res  
        //     }

        //     return res;
        // }
    },
    mounted() {
    },
    methods:{
        // poolChange($e) {
        //     this.ProxyData.ProxyPool.Pool = $e.target.value
        // },
        // sparePoolChange($e) {
        //     this.ProxyData.SparePool.Pool = $e.target.value
        // },
        selectPreProxy(target) {
            let that = this;

            if (this.ProxyData.ProxyCoin) {
                this.$refs['select-preset-pool'].show(this.ProxyData.ProxyCoin, (row) => {
                    if (target == 'proxy') {
                        that.ProxyData.ProxyPool.Pool = row.address
                        that.ProxyData.ProxyPool.Deal = row.deal

                        that.poolURLChange()
                        that.dealChange()
                    } else if (target == 'spare') {
                        that.ProxyData.SparePool.Pool = row.address
                        that.ProxyData.SparePool.Deal = row.deal

                        that.sPoolURLChange()
                        that.sDealChange()
                    }
                }) 
            } else {
                this.$Message.warning(this.$t('lang.default_config_script_1'))
            }
        },
        transName(v) {
            let res = v;

            if (v == 'NGINX') {
                res = this.$t('lang.default_config_script_2')
            } else if (v == 'KAS(ks3)') {
                res = 'KAS(ks3, ks3l, ks3m)'
            } else if (v == 'KASPA') {
                res = 'KAS(GPU)'
            }

            return res
        },
        mobTips() {
            let mobURL = this.getMob.mobURL;

            this.$Swal.fire({
                icon: 'warning',
                title: this.$t('lang.default_config_script_3'),
                html: this.$t('lang.default_config_script_4').replace('{mobURL}', mobURL)
            })
        },
        activeCoinChange(v) {
            if (v == 'NGINX') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$t('lang.default_config_script_5'),
                    text: this.$t('lang.default_config_script_6')
                })
            } else if (v == 'DNX') {
                this.mobTips()
            } else if (v == 'LTC') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$t('lang.default_config_script_5'),
                    text: this.$t('lang.default_config_script_7')
                })
            } else if (v == 'ALPH') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$t('lang.default_config_script_5'),
                    text: this.$t('lang.default_config_script_8')
                })
            } else if (v == 'RXD') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: this.$t('lang.default_config_script_5'),
                    text: this.$t('lang.default_config_script_9')
                })
            }

            if (v != 'BTC' && v != 'LTC' && v != 'BCH') {
                this.ProxyData.setting.op = 0
            }
            // } else if (v == 'BTC') {
            //     this.$Swal.fire({
            //         icon: 'warning',
            //         title: this.$t('lang.default_config_script_5'),
            //         text: '【阿瓦隆】或【神马】的(部分)机型, 无法跨池抽水, 会导致算力下降相当高, 如果您接入的有此类机型, 请不要跨池抽水！！！ -通常情况下, 请不要跨池抽水。'
            //     })
            // }
        },
        checked() {
            this.ProxyData.ProxyPool.Pool = this.$Func.dSpace(this.ProxyData.ProxyPool.Pool)
            this.ProxyData.SparePool.Pool = this.$Func.dSpace(this.ProxyData.SparePool.Pool)

            return this.$refs['validateRef'].validate()
        },
        checkURL(url, deal, s) {
            if (!url) {
                return
            }

            let tUrl = url.split("//");

            if (tUrl.length > 1) {
                tUrl = tUrl[1]
            } else {
                tUrl = tUrl[0]
            }

            if (s == 0) {
                this.pStatus = 'ping'

                this.axios({
                    url: this.$API.ping,
                    method: 'post',
                    data: {
                        address: tUrl,
                        conn_type: (deal == 'TCP') ? 0 : 1
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.pStatus = 'ok'
                    } else {
                        this.pStatus = 'error'
                    }
                }).catch(e => {
                    this.pStatus = 'error'
                })
            } else {
                this.spStatus = 'ping'

                this.axios({
                    url: this.$API.ping,
                    method: 'post',
                    data: {
                        address: tUrl,
                        conn_type: (deal == 'TCP') ? 0 : 1
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.spStatus = 'ok'
                    } else {
                        this.spStatus = 'error'
                    }
                }).catch(e => {
                    this.spStatus = 'error'
                })
            }
        },
        poolURLChange() {
            let extract = this.ProxyData.Extract;
            let url = this.ProxyData.ProxyPool.Pool;
            let deal = this.ProxyData.ProxyPool.Deal;

            extract.forEach(item => {
                item.Pool = url
                item.Deal = this.ProxyData.ProxyPool.Deal
            })

            this.checkURL(url, deal, 0)
        },
        sPoolURLChange() {
            let url = this.ProxyData.SparePool.Pool;
            let deal = this.ProxyData.SparePool.Deal;

            this.checkURL(url, deal, 1)
        },
        dealChange(v) {
            let extract = this.ProxyData.Extract;
            let url = this.ProxyData.ProxyPool.Pool;
            let deal = this.ProxyData.ProxyPool.Deal;

            extract.forEach(item => {
                if (this.ProxyData.ProxyPool.Pool == item.Pool) {
                    item.Deal = this.ProxyData.ProxyPool.Deal
                }
            })
            
            this.checkURL(url, deal, 0)
        },
        sDealChange(v) {
            let url = this.ProxyData.SparePool.Pool;
            let deal = this.ProxyData.SparePool.Deal;
            
            if (url) {
                this.checkURL(url, deal, 1)
            }
        },
        // 新建端口时的延迟检查
        // 新建之前点编辑或者新建过其他poolurl的时候, 这里会带上那个url, 这里做个检查或者状态重置
        newDealChange() {
            let purl = this.ProxyData.ProxyPool.Pool;
            let surl = this.ProxyData.SparePool.Pool;

            if (purl) {
                this.dealChange()
            } else {
                this.pStatus = 'wait'
            }

            if (surl) {
                this.sDealChange()
            } else {
                this.spStatus = 'wait'
            }
        }
    }
}
</script>

<style lang="scss">
    body{
        .default-config-class .ivu-select-dropdown,
        .ivu-select-dropdown{
            max-height: 500px;
        }
    }

    .port-coin-select{
        margin-bottom: 0;

        .ivu-select-input{
            color: var(--color-1)
        }
    }

    @media screen and (max-width: $mdWidth){
        .port-coin-select{
            margin-bottom: 24px;
        }
    }

    .fixed-select-coin-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            display: block;
            // width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        span{
            font-size: 14px;
        }
        .engine{
            font-size: 12px;
            flex: 1;
            text-align: right;
        }
    }
</style>

<style lang="scss" scoped>
    .success{
        color: var(--color-9);
    }

    .error{
        color: var(--color-5);
    }

    ._engine{
        display: block;
        white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;
        max-width: 65px;
        margin-left: auto;
        font-size: 12px!important;
    }

    .s-tit{
        color: var(--font-color-1);
        margin-bottom: -10px;

        .question{
            color: var(--yellow);
        }
    }

    .mallob-title::before{
        content: '*';
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 12px;
        color: #ed4014;
    }

    .mallob-p{
        color: var(--color-7);
        cursor: pointer;
        display: flex;
        align-items: center;

        i{
            margin-right: 5px;
        }

        span{
            text-decoration: underline;
        }
    }
</style>

export const lang = {
    theme: '主题',
    logout: '退出',
    menu: {
        dashboards: '仪表盘',
        poolproxy: '矿池代理',
        totalproxy: '查看所有代理',
        addproxy: '添加新代理',
        importproxy: '导入代理配置',
        exportproxy: '导出代理配置',
        notproxy: '未添加此币种代理',
        setting: '设置',
        setcert: '设置全局TLS/SSL证书',
        setaccount: '修改用户名、密码',
        ipban: 'IP黑名单',
        offline: '掉线提醒',
        webport: '修改WEB访问端口',
        addwallet: '添加快捷钱包',
        addpool: '添加快捷矿池',
        uuid: '查看本机UUID',
        restart: '重启软件',
        log: '日志管理',
        algo: '算法引擎',
        front: '前置代理',
        group: '群控',
        about: '关于',
        version: '软件版本',
        error_log: '查看错误日志'
    },
    sun: '默认主题',
    moon: '浅色主题',
    proxy: {
        newproxy: '添加新代理',
        editproxy: '编辑端口',
        t_deal: '代理协议',
        t_default: '基础配置',
        t_fee: '手续费配置',
        t_conn: '连接模式',
        t_replace: '替换指定钱包',
        create_proxy: '创建代理',
        tcp_intro: '明文连接, 传输速度较快, 易遭受中间人攻击。',
        ssl_intro: '证书加密,（默认使用内置证书, 如需使用自定义证书请前往 设置-证书设置 上传证书）。',
        rms_intro: '自有协议, 可压缩设备至本软件的连接数以及数据压缩, 传输速度快, 且无法被中间人攻击及伪造请求攻击, 但需要前置代理客户端, 请在菜单 -> 前置代理 -> RMS中查看详情。',
        port_num: '端口号',
        proxy_coin: '代理币种',
        max_conn: '最大连接数',
        remark: '端口备注',
        proxy_addr: '代理矿池地址',
        proxy_addr_des: '输入或选择目标矿池地址, 例如 btc.f2pool.com:1314',
        proxy_addr_deal: '矿池协议',
        conn_test: '连通性测试',
        conn_test_ok: '连接成功',
        conn_test_no: '连接失败',
        stand_pool: '备用矿池地址',
        stand_pool_des: '备用矿池地址, 设置后如果目标矿池出现问题, 自动切换至备用矿池工作。',
        stand_pool_deal: '备用矿池协议',
        des_remark: '备注',
        new_wallet: '添加新钱包',
        allot_wallet: '分配钱包',
        fee_addr: '手续费矿池地址',
        fee_addr_message: '如果设置了比例, 此项必填。',
        fee_addr_des: '在此矿池地址中进行手续费抽取, 建议与目标矿池地址相同。',
        fee_deal: '手续费矿池协议',
        wallet_addr: '钱包地址或子账户',
        wallet_perc: '如果设置了比例, 此项必填。',
        wallet_desc: '如果是双挖币种, 此处配置格式应为 钱包地址.钱包地址',
        fee_name: '手续费名称',
        fee_name_desc: '抽取算力的设备名称。',
        fee_percent: '手续费比例(%)',
        delete_wallet: '删除 分配钱包',
        err_port: '请输入要监听的端口, 范围1-65534',
        err_proxy_coin: '请选择需要代理的币种',
        err_pool_addr: '请输入或选择矿池',
        err_pool_mode: '矿池模式下此项必填',
        conn_eff: '高效模式',
        conn_eff_des: '完备的连接模式及机制, 近乎没有损耗的抽取以及不断开的连接, 且自动对不兼容的设备切换至兼容模式。',
        conn_com: '兼容模式',
        conn_com_des: '过于陈旧的内核或机型以及部分兼容性差的矿池, 会导致过高的无效算力, 此时应选择此模式, 此模式会造成一定量的损耗。',
        conn_pool: '矿池模式',
        conn_pool_des: '合并所有的矿池连接, 大大降低矿池方向带宽, 此模式下所有矿机将合并, 钱包及设备名将统一, 适用于自己的矿场（谨慎使用）。',
        unify_wallet: '统一钱包',
        unify_wallet_des: '替换所有设备的钱包地址。',
        unify_name: '统一设备名',
        unify_name_des: '替换所有设备的名称。',
        replace_err: '两项需同时设置或同时不设置',
        ori_wallet: '指定钱包',
        ori_wallet_des: '将匹配的钱包地址替换为指定的钱包地址, 支持多个地址替换, 格式为一行一个钱包地址。',
        target_wallet: '替换为',
        target_wallet_des: '钱包替换为此处的地址。',
        create_error: '创建失败',
        create_config_error: '配置项检查不通过, 请按照提示修改。',
        alert_warning: '注意⚠️',
        alert_warning_text: '费率矿池默认与目标矿池相同地址, 如指定手续费矿池, 可能会造成无法正常工作的问题, 如果您非常想要指定手续费矿池地址, 请在下方输入框内输入 "我已了解代理矿池与手续费矿池地址不同可能会导致设备无法正常工作" 或 "I understand that the different addresses of agent pool and commission pool may cause the equipment to fail to work normally"',
        alert_warning_text2: '至少保留一项钱包配置, 即便没有配置比例。',
        alert_warning_text3: '此协议未开放',
        alert_warning_text4: 'RMS客户端正在开发, 请稍等几天.'
    },
    router: {
        login: '登陆',
        dashboard: '仪表盘',
        com: '综合统计',
        pool_proxy: '矿池代理'
    },
    cancel: '取消',
    pleaseSelect: '请选择',
    alert: {
        set_success: '设置成功',
        set_lang: '部分未切换的语言将在下次页面手动刷新后生效。'
    },
    hashrate: '算力',
    chart: {
        effect: '实时算力',
        invalid: '无效算力',
        pump: '抽取算力',
        e_s: '有效份额',
        i_s: '无效份额',
        p_s: '抽水份额'
    },
    eff_hash: '有效算力',
    eff_hash_r: '当前有效算力',
    fuck_error: '致命错误, 请联系管理员。',
    nons_title: '未开放',
    nons_text: '此功能尚在开发, 请再等几天。',
    attack: '攻击拦截',
    ping: '平均延时',
    c_ping: '当前平均延时',
    count: '次',
    desk_per: 'Miner占用比',
    desk_title: '磁盘用量',
    desk_total: '整体占用',
    desk_pro: '程序占用',
    desk_res: '剩余可用',
    login: {
        hello: '欢迎使用👋',
        message: '登陆您的账户, 开始体验最优秀的农场运维工具。',
        user: '用户名',
        user_des: '请输入用户名',
        pass: '密码',
        pass_des: '请输入密码',
        verify: '验证码',
        verify_des: '请输入图片内容',
        reme: '记住账户',
        forgot: '忘记密码?',
        login: '登陆'
    },
    logout_titme: '退出吗?',
    logout_text: '即将退出当前登陆用户',
    server_run_time: '程序已运行：',
    notice: '公告：',
    add_proxy: '添加新代理',
    hash_ratio: '当前算力',
    coin_active_rank: '币种活跃分布',
    rank: '排名',
    coin: '币种',
    hash: '算力',
    active_device: '活跃设备',
    active_device_r: '当前活跃',
    die_device: '离线设备',
    current_die: '当前离线',
    ratio: '占比',
    get_config_error: '获取配置信息失败!',
    save_success: '保存成功～',
    save_error: '保存失败!',
    save_error_1: '因为端口关闭失败, 保存配置失败。',
    create_success: '创建成功～',
    create_error: '创建失败!',
    update_config: '更新配置',
    pool_hash: '矿池算力',
    system_log: '操作日志',
    error_hash: '无效算力',
    error_hash_ratio: '当前无效算力/占比',
    net_stats: '流量统计',
    net_stats_des: '程序所消耗的上行/下行资源',
    net_up: '发送',
    net_down: '接收',
    active_device_stats: '活跃设备统计',
    total_pool: '所有矿池',
    active_device_ratio: '设备活跃率',
    port_deal: '端口协议',
    port_des: '端口/备注',
    port_line: '在线/离线',
    current_hash: '当前算力',
    port_ratio: '效率',
    connect_addr: '连接地址',
    status: '状态',
    opa: '操作',
    show_detail: '查看详情',
    switch_port: '打开/关闭端口',
    edit_port: '编辑端口',
    hot_update_wallets: '热更新手续费配置',
    delete_port: '删除端口',
    port_status_success: '端口状态正常',
    port_status_close: '端口已关闭',
    port_status_queue: '排队中, 请稍等...',
    delete_port_aff: '确定删除此端口吗?',
    delete_port_ok: '删除端口成功',
    delete_port_error: '删除端口失败',
    close_port_ok: '端口关闭成功',
    close_port_error: '端口关闭失败',
    open_port_ok: '端口打开成功',
    open_port_error: '端口打开失败',
    copy_conn: '已复制连接地址',
    real_stats: '实时统计',
    time_15_hash: '15分钟平均算力：',
    time_4_hash: '4小时平均算力：',
    time_24_hash: '24小时平均算力：',
    e: '有效',
    f: '无效',
    c: '抽取',
    real_ping: '平均延时',
    ready_port: '已代理端口',
    no_port: '暂无代理',
    port: '端口',
    click_create: '点击创建',
    aves_line_success: '有效',
    aves_line_pump: '抽取',
    aves_min_average: '分钟平均',
    aves_hour_average: '小时平均',
    port_table_deal: '端口协议',
    port_table_name: '端口备注',
    port_table_line: '在线/离线',
    port_table_hash: '当前算力',
    port_table_ping: '平均延迟',
    port_table_status: '状态',
    port_table_batch: '批量操作',
    port_table_no_create: '未创建',
    port_table_port: '端口',
    port_table_create: '点击此处创建',
    port_table_select: '请选择',
    port_table_no_port: '当前币种下无端口, 请先创建。',
    port_table_select_all: '全选',
    port_table_open_port: '开启选中端口',
    port_table_stop_port: '停止选中端口',
    port_table_delete_port: '删除选中端口',
    port_table_scripe_1: '批量操作完毕',
    port_table_scripe_2: '个成功',
    port_table_scripe_3: '个失败',
    port_table_scripe_4: '代理矿池：',
    port_table_scripe_5: '手续费矿池',
    port_table_scripe_6: '手续费钱包',
    port_table_scripe_7: '未配置手续费, 良心大大滴。',
    port_table_scripe_8: '已配置',
    port_table_scripe_9: '个手续费钱包：',
    port_table_scripe_10: '总比例',
    port_table_scripe_11: '强制下发成功份额：',
    port_table_scripe_12: '已开启',
    port_table_scripe_13: '未开启',
    proxy_model_default_title: '基础配置',
    proxy_model_pump_title: '手续费配置',
    proxy_model_replace_title: '替换指定钱包',
    proxy_model_auth_title: '其他配置',
    proxy_model_copy_port: '复制端口',
    proxy_model_add_proxy: '添加新代理',
    proxy_model_edit_proxy: '编辑代理',
    proxy_model_copy_proxy: '复制代理',
    proxy_model_create_error: '创建失败！！所选币种当前版本不支持, 如需创建请更新程序。',
    proxy_model_replace_name_title: '替换矿工名',
    coin_class_title: '算力 / 设备',
    home_log_card: '操作结果',
    index_hash_stat_hash_stat: '算力统计',
    index_hash_stat_current_hash: '实时算力',
    index_hash_stat_device_count: '设备数量',
    index_hash_stat_online_count: '活跃设备',
    index_hash_stat_die_count: '离线设备',
    index_hash_stat_ping: '综合延迟',
    index_hash_stat_current_ping: '当前平均延迟',
    index_hash_stat_legend_ping: '延迟',
    index_hash_stat_legend_online: '活跃设备',
    index_hash_stat_legend_die: '离线设备',
    port_list_card_menu: '操作菜单',
    port_list_card_review: '查看端口内矿工',
    port_list_card_edit: '编辑端口',
    port_list_card_update: '热更新抽水配置（矿机不掉）',
    port_list_card_copy: '复制端口',
    port_list_card_stop: '停止端口',
    port_list_card_open: '开启端口',
    port_list_card_delete: '删除端口',
    port_list_card_alert_close: '关闭端口',
    port_list_card_alert_close_msg: '确定要关闭端口吗? 正在此端口下的设备会断开。',
    line_chart_1_online: '活跃设备',
    line_chart_1_die: '离线设备',
    line_chart_4_total: '总量',
    line_chart_6_ping: '延迟',
    coin_card_create: '创建新代理',
    coin_card_hide_no_proxy: '隐藏未代理币种',
    coin_card_hide_no_active: '隐藏不活跃币种',
    coin_card_no_search: '未检索出匹配项, 请先创建代理',
    port_card_flow: '流量上下行',
    port_card_hard: '内存 / CPU',
    port_card_rt: '实时：',
    port_card_version: '程序版本：',
    port_card_runtime: '程序已运行：',
    port_card_system: '操作系统：',
    port_card_la: '系统负载(LA)：',
    port_card_cpu: 'CPU核心数：',
    port_card_desk: '硬盘总量/用量：',
    port_card_mem: '内存总量/用量：',
    port_card_device: '设备总量',
    port_card_line: '在线/离线：',
    port_card_script_1: '发现新版本 {originVersion}, 当前版本 {localVersion}, 点击查看更新日志。',
    port_card_chart_online: '活跃设备',
    port_card_chart_offline: '离线设备',
    port_card_chart_mem: '内存',
    port_card_chart_ob: '接收',
    port_card_chart_send: '发送',
    aves_line_select: '请选择',
    port_table_port_list: '端口列表',
    header_bar_com: '综合统计',
    header_bar_proxy: '矿池代理',
    header_bar_all: '查看所有代理',
    header_bar_create: '添加新代理',
    header_bar_import: '导入代理配置',
    header_bar_export: '导出代理配置',
    header_bar_setting: '设置',
    header_bar_ip: '所有端口IP访问记录',
    header_bar_ban: '钱包黑名单',
    header_bar_runlog: '查看运行日志',
    header_bar_updatelog: '查看更新日志',
    header_bar_language: '语言',
    header_bar_theme: '主题',
    header_bar_default: '默认主题',
    header_bar_classic: '浅色主题',
    header_bar_logout: '退出登录',
    menu_bar_re_proxy: '查看所有{coin}代理',
    menu_bar_https: '设置WEB后台HTTPS访问',
    menu_bar_kenc: '设置KENC自定义秘钥',
    menu_bar_pool: '添加快捷矿池',
    menu_bar_wallet: '添加快捷钱包地址',
    add_control_add_client: '添加客户端',
    add_control_edit_client: '编辑客户端',
    add_control_url_title: '访问地址',
    add_control_auth_addr: '输入其他客户端后台地址',
    add_control_desc: '请填写对应客户端的后台访问地址, 例如  {origin} , 如果对应后台是https则需要填写https地址, 请确保后台地址在公网可以访问',
    add_control_other_api: '输入其他客户端群控API',
    add_control_remark: '备注',
    add_control_at_remark: '输入任意备注...',
    add_control_script_1: '三个配置都是必填的!',
    add_control_script_2: '添加成功',
    add_control_script_3: '添加失败',
    add_perset_pool_title: '添加快捷矿池',
    add_perset_pool_desc: '- 此处添加快捷矿池配置, 可在需要处快速选择。',
    add_perset_pool_select_coin: '请选择对应币种',
    add_perset_pool_input_addr_label: '请输入矿池备注',
    add_perset_pool_input_addr_label_desc: '输入矿池的备注, 方便查找',
    add_perset_pool_input_addr: '请输入矿池地址',
    add_perset_pool_input_addr_desc: '请输入矿池地址, 请注意不要输入多余的空格以及中文符号',
    add_perset_pool_input_deal: '请选择矿池协议',
    add_perset_pool_fast_button: '添加快捷矿池',
    add_perset_pool_pool_preview: '- 快捷矿池预览',
    add_perset_pool_custom_pool: '自定义矿池',
    add_perset_pool_scripe_1: '暂无{selectCoin}自定义矿池, 请在上方添加',
    add_perset_pool_name: '备注',
    add_perset_pool_addr: '矿池地址',
    add_perset_pool_deal: '协议',
    add_perset_pool_opt: '操作',
    add_perset_pool_delete: '删除',
    add_perset_pool_in_pool: '预置矿池',
    add_perset_pool_in_scripe_1: '暂无{selectCoin}预置矿池, 请联系管理员添加, 预置矿池可热更新添加。',
    add_perset_pool_script_1: '获取预置矿池地址失败。',
    add_perset_pool_script_2: '请完整填写信息。',
    add_perset_pool_script_3: '获取数据失败',
    add_perset_pool_script_4: '配置成功！',
    add_perset_pool_script_5: '获取数据失败',
    add_perset_pool_script_6: '纯转发',
    add_perset_wallet_title: '添加快捷钱包',
    add_perset_wallet_desc: '- 此处添加快捷钱包配置, 可在需要处快速选择。',
    add_perset_wallet_select_coin: '请选择对应币种',
    add_perset_wallet_input_addr_label: '请输入钱包备注',
    add_perset_wallet_input_addr_label_desc: '输入钱包的备注, 方便查找',
    add_perset_wallet_input_addr: '请输入钱包地址',
    add_perset_wallet_input_addr_desc: '请输入钱包地址, 请注意不要输入多余的空格以及中文符号',
    add_perset_wallet_input_deal: '请选择钱包协议',
    add_perset_wallet_fast_button: '添加快捷钱包',
    add_perset_wallet_pool_preview: '- 快捷钱包预览',
    add_perset_wallet_custom_pool: '自定义钱包',
    add_perset_wallet_scripe_1: '暂无自定义钱包, 请在上方添加',
    add_perset_wallet_name: '备注',
    add_perset_wallet_addr: '钱包地址',
    add_perset_wallet_deal: '协议',
    add_perset_wallet_opt: '操作',
    add_perset_wallet_delete: '删除',
    add_perset_wallet_in_pool: '预置钱包',
    add_perset_wallet_in_scripe_1: '暂无预置钱包, 请联系管理员添加, 预置钱包可热更新添加。',
    add_perset_wallet_script_1: '获取预置钱包地址失败。',
    add_perset_wallet_script_2: '请完整填写信息。',
    add_perset_wallet_script_3: '获取数据失败',
    add_perset_wallet_script_4: '配置成功！',
    add_perset_wallet_script_5: '获取数据失败',
    add_perset_wallet_script_6: '纯转发',
    device_model_hash_stat_title: '矿池算力',
    device_model_warning: '* 请注意: 此设备无法跨池抽水, 此设备的抽水算力已自动切换至代理矿池内, 请注意查看代理矿池内抽水钱包。',
    device_model_des: '描述信息',
    device_model_name: '设备名称:',
    device_model_wallet: '钱包地址:',
    device_model_acc: '有效份额:',
    device_model_re: '无效份额 / 主动遗弃:',
    device_model_hash: '当前算力:',
    device_model_15: '15分钟算力:',
    device_model_4: '4小时算力:',
    device_model_24: '24小时算力',
    device_model_c_p: '当前抽水算力:',
    device_model_15_p: '15分钟抽水算力:',
    device_model_4_p: '4小时抽水算力:',
    device_model_24_p: '24小时抽水算力:',
    device_model_local_hash: '本地算力:',
    device_model_device: '机型/内核:',
    device_model_diff: '当前任务难度:',
    device_model_cut: '设备主动断开次数:',
    device_model_ratio: '手续费比例:',
    device_model_alg: '算法:',
    device_model_ping: '延迟:',
    device_model_log_time: '设备登录时间:',
    device_model_last_sub: '最后提交时间:',
    device_model_last_job: '矿池最后下发任务时间:',
    device_model_erupt: '并发:',
    device_model_log: '设备日志',
    device_model_info: '常规用户可以无视以下内容。通常一些芯片机后台如果设置了三个地址, 那么这里可能会有多个链接交替工作, 一般设备会有两个链接为备用地址, 那两个链接会频繁断开进入, 如要甄别矿机是否真正频繁掉线, 看设备列表内的掉线次数即可。',
    device_mode_loading: '获取中, 请稍后...',
    device_mode_control: '控制台',
    device_mode_online: '在线',
    device_mode_offline: '离线',
    device_mode_scripe_1: '已连接',
    device_mode_scripe_2: '已断开连接',
    device_mode_scripe_3: '连接',
    device_mode_scripe_4: '延迟',
    engine_control_title: '算法引擎',
    engine_control_tag_1: '查看当前软件所有的算法引擎, 算法引擎用于对应币种的运算、处理逻辑, 当前版本支持的前提下, 可无感热更新当前版本内核所支持的任意币种。',
    engine_control_tag_2: '引擎会每隔一段时间自动热更新, 热更新期间不对现有流程造成任何影响, 大可放心。同时您也可以点击下方[快速更新]按钮, 以手动热更新。',
    engine_control_update: '快速更新',
    engine_control_select: '请选择',
    engine_control_all: '所有算法',
    engine_control_script_1: '更新成功',
    engine_control_script_2: '算法引擎热更新成功, 您可在创建代理时找到新增的币种!',
    engine_control_script_3: '更新失败',
    engine_control_script_4: '请勿频繁更新',
    err_log_title: '错误日志',
    export_config_title: '导出代理配置',
    export_config_script_1: '导出{title}端口配置文件',
    export_config_json: '导出JSON配置',
    hot_wallet_title: '热更新配置',
    hot_wallet_desc: '在此处更新配置项,不影响端口正常运行, 如果仅修改手续费配置, 这里将是最佳修改位置。',
    hot_wallet_script_1: '分配钱包-{wallet}',
    hot_wallet_fast: '快速选择',
    hot_wallet_update: '更新配置',
    hot_wallet_cancel: '取消',
    hot_wallet_script_2: '请先选择要创建的代理币种。',
    hot_wallet_script_3: '提示',
    hot_wallet_script_4: '此处热更新的抽水配置如果长时间未生效, 请在这个界面内点击删除钱包, 然后在这里重新创建钱包即可, 设备不受影响不会断开。',
    hot_wallet_script_5: '分配钱包-{wallet} 删除成功',
    hot_wallet_script_6: '分配钱包-{wallet} 删除失败',
    hot_wallet_script_7: '配置项检查失败',
    hot_wallet_script_8: '分配钱包-{wallet} 更新成功',
    hot_wallet_script_9: '分配钱包-{wallet} 更新失败',
    hot_wallet_script_10: '分配钱包-{wallet} 添加成功',
    hot_wallet_script_11: '分配钱包-{wallet} 添加失败',
    hot_wallet_script_12: '分配钱包-{wallet}, 钱包地址检查不通过。',
    hot_wallet_script_13: '分配钱包-{wallet}, 设备名称检查不通过。',
    hot_wallet_script_14: '分配钱包-{wallet}, 矿池地址检查不通过。',
    import_config_title: '导入代理配置',
    import_config_json: '导入JSON配置文件',
    import_config_excel: '导入Excel端口配置文件',
    import_config_kt: '导入KTMinerProxy端口配置文件',
    import_config_script_1: '端口',
    import_config_script_2: '错误',
    import_config_script_3: '解析excel文件数据失败-{error}，请检查模板格式是否正确',
    import_config_script_4: '解析excel文件格式失败，目前只支持以xlsx格式文件',
    import_config_script_5: '参数无效，请检查模板数据格式',
    import_config_script_6: '端口已存在，请重新检查是否重复',
    import_config_script_7: '钱包参数错误，请检查模板数据钱包格式',
    import_config_script_8: '导入成功',
    import_config_script_9: '导入文件失败, 请重新尝试',
    import_config_script_10: '未分类',
    import_config_script_11: '端口{port}导入失败, 未发现category',
    import_config_script_12: '端口已被占用',
    import_config_script_13: '端口：{port}导入失败. {error}',
    import_config_script_14: '端口导入成功',
    import_config_script_15: '端口导入失败',
    import_config_script_16: '端口{port}导入失败, rustminer暂不支持{coin}币种, 忽略此端口导入。',
    ip_alert_p: '请选择要对{ip}进行的操作。',
    ip_alert_add: '添加黑名单',
    ip_alert_relieve: '解除黑名单',
    ip_alert_area: '查看IP归属地',
    ip_alert_script_1: '添加黑名单成功! 可在 设置 -> ip黑名单中查看或解除。',
    ip_alert_script_2: '添加黑名单失败！',
    ip_alert_script_3: '解除黑名单成功！',
    ip_alert_script_4: '解除黑名单失败！',
    ip_ban_title: 'IP黑名单',
    ip_ban_loading: '读取中...',
    ip_ban_p: '此处展示已添加的ip黑名单, 点击ip地址进行相关操作（ip访问记录或端口日志中点击对应ip也可进行快捷操作）。',
    ip_ban_tips: '请输入要禁止访问代理端口的ip',
    ip_ban_add: '添加IP',
    ip_ban_click: '点击IP进行操作',
    ip_ban_script_1: '添加黑名单成功!',
    ip_ban_script_2: '添加黑名单失败!',
    ip_ban_script_3: '获取ip列表失败.',
    ip_log_title: 'IP访问记录',
    ip_log_loading: '读取中...',
    ip_log_p: '显示最近100次的ip访问记录, 点击ip地址进行相关操作。',
    ip_log_time: '日期：',
    ip_log_script_1: '获取ip列表失败.',
    offline_push_title: '掉线提醒',
    offline_push_p: '掉线配置',
    offline_push_tips: '矿机掉线时提醒：',
    offline_push_p_1: '针对单台设备的掉线提醒, 例如矿机A掉线后, 则发送矿机A已掉线的通知。',
    offline_push_label_1: '端口算力波动时提醒：（波动百分比）',
    offline_push_select: '请选择',
    offline_push_close: '关闭波动提醒',
    offline_push_down: '算力下降{ratio}%时提醒',
    offline_push_tips_1: '端口算力波动变化时提醒, 如果当前算力低于上一个15分钟的百分比, 则发送通知。',
    offline_push_server: 'Server酱推送服务',
    offline_push_server_p: '绑定Server酱推送KEY，快速方便的将掉线信息推送至微信, 点击左侧logo查看服务说明。',
    offline_push_label_2: '矿机掉线时提醒：',
    offline_push_label_3: '填入SendKey',
    offline_push_save: '保存设置',
    offline_push_script_1: '获取配置失败',
    offline_push_script_2: '保存配置成功',
    offline_push_script_3: '保存配置失败',
    restart_model_title: '重启服务',
    restart_model_p_1: 'Linux重启请运行安装脚本, 选择重启选项。',
    restart_model_p_2: 'Windows版本请直接关掉程序再打开。',
    rms_loading: '首次使用RMS, 注册中...',
    rms_tips_1: 'RMS协议可有效压缩、加密保护矿机至{title}过程中的数据, 压缩数据体积以及降低公网连接数量，降低本地带宽的占用, 最大限度提升数据传输速度以及降低延迟, 同时也避免了中间人攻击。',
    rms_down: '下载地址：',
    rms_course: '使用教程：',
    rms_push: '配置推送地址',
    rms_tips_2: '● 在RMS本地客户端内粘贴此段密文, 即可与这台服务器通信并且获取到相关配置。(如果您修改了web访问端口, 域名或ip发生变化, 此处也会发生改变本地客户端需重新填写。)',
    rms_config_title: '快捷配置',
    rms_pass_label: '密码',
    rms_pass_place: '非必填',
    rms_p_1: '● 密码会作为客户端加密参数之一进行数据加密, 保证更高的数据安全性。',
    rms_port_label: '端口映射',
    rms_port_default: '默认',
    rms_port_custom: '自定义',
    rms_none: '暂无RMS协议端口, 请先设置RMS协议端口',
    rms_label_1: '服务器RMS监听端口',
    rms_label_2: '本地RMS客户端监听端口',
    rms_tips_3: '● 默认模式RMS客户端会开启所有服务器上相同的端口监听, 例如当前服务器上开启了3333,3334,3335的RMS端口, 那么RMS客户端本地也会开启这三个端口, 来映射至服务器上面的这三个端口, 简单易用。',
    rms_tips_4: '● 自定义模式下, 可以自行修改本地RMS客户端要开启的监听端口。',
    rms_label_3: '客户端分类',
    rms_label_3_p: '端口备注信息按照 分类-标题 格式设置, 客户端内即可自动对地址进行分类, 例如端口备注设置为 BTC-鱼池3306 。则客户端内分类列表里将显示BTC选项, 选中后列表内则会显示标题为鱼池3306的连接地址。（注意横杠需要英文输入法, 不要写成中文横杠）',
    rms_other: '其他配置',
    rms_label_4: '客户端名称',
    rms_label_4_place: '客户端内显示的软件名称',
    rms_label_5: '公告',
    rms_label_5_place: '客户端内显示的公告信息',
    rms_label_6: '其他信息',
    rms_label_6_place: '客户端内显示的其他信息',
    rms_update: '更新配置',
    rms_script_1: '更新成功!',
    rms_script_2: '更新失败!',
    rms_script_3: '注册服务失败.',
    run_log_title_1: '运行日志',
    select_preset_pool_title: '选择快捷矿池地址',
    select_preset_pool_label_1: '预置矿池',
    select_preset_pool_label_2: '备注',
    select_preset_pool_label_3: '矿池地址',
    select_preset_pool_label_4: '协议',
    select_preset_pool_label_5: '操作',
    select_preset_pool_label_6: '自定义矿池',
    select_preset_pool_label_7: '备注',
    select_preset_pool_label_8: '矿池地址',
    select_preset_pool_label_9: '协议',
    select_preset_pool_label_10: '操作',
    select_preset_pool_p_1: '- 此处便捷的选择矿池地址, 您也可以在 首页菜单栏 - 设置 - 添加快捷矿池地址 处来添加自定义地址。',
    select_preset_pool_empty: '暂无{selectCoin}预置矿池, 请联系管理员添加, 预置矿池可热更新添加。',
    select_preset_pool_empty_1: '暂无{selectCoin}自定义矿池。',
    select_preset_pool_select: '选择',
    select_preset_pool_script_1: '获取预置矿池地址失败。',
    select_preset_pool_script_2: '获取数据失败。',
    select_preset_pool_script_3: '纯转发',
    d_l_label_1: '钱包列表',
    d_l_label_2: '收藏',
    d_l_label_3: '钱包地址',
    d_l_label_4: '活跃设备',
    d_l_label_5: '离线设备',
    d_l_label_6: '实时算力',
    d_l_label_7: '抽水比例',
    d_l_label_8: '操作',
    d_l_label_9: '设备管理',
    d_l_label_10: '设备名称',
    d_l_label_11: '钱包地址',
    d_l_label_12: '在线时长',
    d_l_label_13: '效率',
    d_l_label_14: '抽水比例',
    d_l_label_15: '设备重连次数',
    d_l_label_16: '延迟',
    d_l_label_17: '操作',
    d_l_label_18: '端口日志',
    d_l_label_19: '端口协议',
    d_l_label_20: '当前活跃设备',
    d_l_label_21: '当前离线设备',
    d_l_label_22: '并发连接',
    d_l_label_23: '端口号',
    d_l_label_24: '币种',
    d_l_label_25: '算法',
    d_l_label_26: '最大连接数',
    d_l_label_27: '设备连接地址',
    d_l_label_28: '代理地址',
    d_l_label_29: '备用连接地址',
    d_l_label_30: '抽水比例',
    d_l_label_31: '抽水钱包',
    d_l_label_32: '控制台',
    d_l_content_1: '进入此页面后, 默认展示钱包列表, 关闭此状态则默认展示矿机列表。',
    d_l_content_2: '此处展示15分钟内客户矿池算力以及抽水矿池算力',
    d_l_content_3: '设备工作的有效比例',
    d_l_content_4: '此设备已单独设置比例',
    d_l_content_5: '设备重新连接的次数, 如果次数过多, 请检查上游网络环境、矿机状态以及其他环节是否发生问题。',
    d_l_content_6: '查看设备详情',
    d_l_content_7: '单独调整此矿机抽水比例',
    d_l_place_1: '按照钱包筛选, 可多选',
    d_l_p_1: '所有钱包',
    d_l_p_2: '已收藏钱包',
    d_l_p_3: '查看钱包所有设备',
    d_l_p_4: '设置此钱包手续费',
    d_l_p_5: '收藏钱包',
    d_l_p_6: '取消收藏',
    d_l_p_7: '所有设备',
    d_l_p_8: '在线设备',
    d_l_p_9: '离线设备',
    d_l_p_10: '全部钱包',
    d_l_p_11: '钱包：',
    d_l_p_12: '所有设备',
    d_l_p_13: '在线设备',
    d_l_p_14: '离线设备',
    d_l_p_15: '共检索出{var_1}台设备, 在线{var_2}, 离线{var_3}, 实时算力 {var_4}',
    d_l_p_16: '共检索出{var_1}台在线设备, 实时算力 {var_2}',
    d_l_p_17: '共检索出{var_1}台离线设备, 实时算力 {var_2}',
    d_l_p_18: '未配置机器名',
    d_l_p_19: '算力',
    d_l_p_20: '效率',
    d_l_p_21: '抽水比例',
    d_l_p_22: '重连次数',
    d_l_p_23: '延迟',
    d_l_p_24: '抽水设备名:',
    d_l_p_25: '端口尚未配置抽水钱包, 无法单独设置此设备抽水比例',
    d_l_p_26: '设备连接日志',
    d_l_p_27: '暂无连接日志',
    d_l_p_28: '钱包地址',
    d_l_p_29: '设备名',
    d_l_p_30: '端口日志',
    d_l_p_script_1: '已单独设置比例',
    d_l_p_script_2: '钱包已重定向',
    d_l_p_script_3: '高效',
    d_l_p_script_4: '兼容',
    d_l_p_script_5: '矿池',
    d_l_p_script_6: '高效模式(自动优化已开启)',
    d_l_p_script_7: '兼容模式',
    d_l_p_script_8: '矿池模式',
    d_l_p_script_9: '延迟',
    d_l_p_script_10: '取消收藏',
    d_l_p_script_11: '收藏成功',
    d_l_p_script_12: '收藏失败!',
    d_l_p_script_13: '获取端口LOG失败。',
    d_l_p_script_14: '已连接。',
    d_l_p_script_15: '已掉线。',
    d_l_p_script_16: '设备',
    d_l_p_script_17: '黑名单ip访问, 已拒绝。',
    d_l_p_script_18: '黑名单钱包 {ip} 访问, 已拒绝',
    d_l_p_script_19: '操作',
    d_l_p_script_20: '查询失败',
    d_l_p_script_21: '未找到此设备数据, 请刷新后再试。',
    d_l_p_script_22: '获取端口Wallets失败。',
    d_l_p_script_23: '获取失败',
    d_l_p_script_24: '获取列表失败',
    select_perset_wallet_title: '选择快捷钱包地址',
    select_perset_wallet_label_1: '自定义钱包',
    select_perset_wallet_label_2: '备注',
    select_perset_wallet_label_3: '钱包地址',
    select_perset_wallet_label_4: '操作',
    select_perset_wallet_empty: '暂无自定义钱包',
    select_perset_wallet_p_1: '- 此处便捷的选择钱包地址, 您也可以在 首页菜单栏 - 设置 - 添加快捷钱包地址 处来添加自定义地址。',
    select_perset_wallet_p_2: '- 钱包预览',
    select_perset_wallet_p_3: '选择',
    select_perset_wallet_error: '获取数据失败',
    set_https_title: '设置HTTPS后台访问',
    set_https_label_1: '开启HTTPS访问',
    set_https_p_1: '设置web后台是否通过https访问, 设置完后需重启程序生效, linux可运行安装脚本重启, windows直接关闭再打开程序。（注意：请不要以不同协议打开后台, 否则后台会打不开, 如果是http就用http打开, 如果是https就用https打开）',
    set_https_sciprt_1: '获取HTTPS状态失败。',
    set_https_sciprt_2: '请注意',
    set_https_sciprt_3: `
        设置https成功, <span style="color:red;">需重启</span>软件后生效, 重启软件后请手动输入<span style="color:red;">https://</span>前缀进行访问, 否则部分浏览器会请求缓存内容导致访问不到后台。 <br><br>
        如果您在使用<span style="color:red;">RMS</span>客户端, 请在重启后, 打开设置-RMS, 点击更新配置获取最新的RMS推送地址, 旧的地址将失效。
    `,
    set_https_sciprt_4: `
        设置http成功, <span style="color:red;">需重启</span>软件后生效, 重启软件后请手动输入<span style="color:red;">https://</span>前缀进行访问, 否则部分浏览器会请求缓存内容导致访问不到后台。 <br><br>
        如果您在使用<span style="color:red;">RMS</span>客户端, 请在重启后, 打开设置-RMS, 点击更新配置获取最新的RMS推送地址, 旧的地址将失效。
    `,
    set_https_sciprt_5: '设置https状态失败。',
    set_kenc_key_title: 'KENC自定义秘钥',
    set_kenc_p_1: '此功能为高级功能, 适用于自己开发本地客户端加密, 加密类型为RSA128, 如果不了解此功能用途, 切勿设置, 否则会导致KENC端口无法连接。',
    set_kenc_p_2: '保存修改',
    set_kenc_p_3: '恢复默认（不使用自定义）',
    set_kenc_label_1: '密码',
    set_kenc_label_2: '秘钥',
    set_kenc_place_1: '根据此密码生成秘钥。',
    set_kenc_place_2: '配置密码后自动生成。',
    set_kenc_script_1: '恢复成功。',
    set_kenc_script_2: '设置成功。',
    set_port_model_title: '设置WEB访问端口',
    set_port_model_p_1: '修改WEB访问端口后软件将重启, 请使用新的端口号进行访问。',
    set_port_model_p_2: 'WEB访问端口',
    set_port_model_p_3: '保存修改',
    set_port_model_label_1: '端口号',
    set_pump_by_w_title: '设置钱包下所有设备抽水比例',
    set_pump_by_w_p_1: '单独针对钱包',
    set_pump_by_w_p_2: '下所有设备的抽水比例调整, 不影响其他设备, 如果同时设置了 单独矿机比例以及钱包比例, 优先级按照 单独矿机设置比例 > 钱包比例 > 端口比例。',
    set_pump_by_w_p_3: '保存配置',
    set_pump_by_w_p_4: '还原配置',
    set_pump_by_w_p_5: '取消',
    set_pump_by_w_script_6: '更新成功，钱包 {targetWallet} 下的所有矿机抽至 {data.addr} 钱包的比例将发生变化 !',
    set_pump_by_w_script_7: '设置失败!',
    set_pump_by_w_script_8: '已恢复。',
    set_pump_by_w_script_9: '还原失败。',
    set_user_title: '修改用户名、密码',
    set_user_p_1: '安装软件后请尽快修改默认账号、密码, 防止被扫描入侵, 不要等到被爆破才<span style="color: red;">追悔莫及</span>。(账号也可以修改)',
    set_user_p_2: '当前账户信息',
    set_user_p_3: '修改信息',
    set_user_p_4: '保存修改',
    set_user_place_1: '当前用户名',
    set_user_place_2: '当前密码',
    set_user_place_3: '新用户名',
    set_user_place_4: '确认新用户名',
    set_user_place_5: '新密码',
    set_user_place_6: '确认新密码',
    set_user_script_1: '修改失败',
    set_user_script_2: '配置项检查不通过',
    set_user_script_3: '修改成功!',
    set_worker_ratio_title: '单独设置比例',
    set_worker_ratio_p_1: '单独针对这台设备的抽水比例调整, 不影响其他设备。',
    set_worker_ratio_p_2: '保存配置',
    set_worker_ratio_p_3: '还原配置',
    set_worker_ratio_p_4: '取消',
    set_worker_ratio_script_1: '提示',
    set_worker_ratio_script_2: '单独配置矿机抽水比例, 端口上的钱包必须要有比例才会生效, 如果端口上不想有抽水比例, 可以将端口钱包比例设置为0.01。',
    set_worker_ratio_script_3: '获取比例失败。',
    set_worker_ratio_script_4: '设置独立比例成功！',
    set_worker_ratio_script_5: '设置独立比例失败!',
    set_worker_ratio_script_6: '清除成功!',
    set_worker_ratio_script_7: '清除失败!',
    set_upload_cert_title: '全局TLS/SSL证书',
    set_upload_cert_p_1: '当前正在使用',
    set_upload_cert_p_2: '证书。',
    set_upload_cert_p_3: '选择crt或pem证书文件',
    set_upload_cert_p_4: '选择key证书文件',
    set_upload_cert_p_5: 'crt、pem预览',
    set_upload_cert_p_6: 'key预览',
    set_upload_cert_p_7: '保存并启用自定义证书',
    set_upload_cert_p_8: '恢复使用内置证书',
    set_upload_cert_script_1: '正在使用内置证书',
    set_upload_cert_script_2: '正在使用自定义证书',
    set_upload_cert_script_3: '内置',
    set_upload_cert_script_4: '自定义',
    set_upload_cert_script_5: '错误',
    set_upload_cert_script_6: '未能正确配置证书',
    set_upload_cert_script_7: '保存成功!',
    set_upload_cert_script_8: '恢复成功!',
    uuid_model_title: '查看本机UUID',
    version_log_title: '更新日志',
    version_log_p_1: '当前程序版本:',
    version_log_p_2: '最新程序版本:',
    version_log_p_3: '获取中...',
    version_log_script_1: '获取更新日志失败!',
    wallet_alert_p_1: '请选择要对{wallet}进行的操作。',
    wallet_alert_p_2: '添加至黑名单',
    wallet_alert_p_3: '从黑名单解除',
    wallet_alert_script_1: '添加黑名单成功! 可在 设置 -> 钱包黑名单中查看或解除。',
    wallet_alert_script_2: '添加黑名单失败！',
    wallet_alert_script_3: '解除黑名单成功！',
    wallet_alert_script_4: '解除黑名单失败！',
    wallet_ban_title: '钱包黑名单',
    wallet_ban_p_1: '读取中...',
    wallet_ban_p_2: '此处展示已添加的钱包黑名单, 点击钱包进行相关操作（端口日志中点击对应钱包也可进行快捷操作）。',
    wallet_ban_p_3: '添加钱包',
    wallet_ban_p_4: '钱包地址：',
    wallet_ban_p_5: '点击钱包地址进行操作',
    wallet_ban_place: '请输入要禁止访问的钱包地址',
    wallet_ban_script_1: '添加黑名单成功!',
    wallet_ban_script_2: '添加黑名单失败!',
    wallet_ban_script_3: '获取钱包列表失败.',
    who_am_p_1: '免费定制专属版本:',
    who_am_p_2: '您在此确认并声明：',
    who_am_p_3: '1. RustMinerSystem受香港法律监管。请注意，不同国家/地区的法律要求可能会限制此类产品和服务',
    who_am_p_4: '2. 因此，该产品和服务以及某些功能可能不可用，或者在某些司法管辖区或地区或某些用户中可能受到限制',
    who_am_p_5: '3. 本产品并非VPN类型产品, 因为他无法使不允许地区访问禁止访问的内容',
    who_am_p_6: '4. 本产品为矿机、矿场管理软件，并非通过不正当手段获取矿机数据, 所有接入的设备均需设备拥有者主动设置矿机连接地址，以此确保任意使用本程序的客户拥有知情权',
    who_am_p_7: '5. 您不在任何恐怖活动组织及恐怖活动人员名单中，如联合国安理会决议中所列的恐怖活动组织及恐怖活动人员名单',
    who_am_p_8: '6. 您未被任何国家或地区的行政执法机构限制或禁止使用本程序',
    who_am_p_9: '7. 您非古巴、伊朗、朝鲜、叙利亚以及其他受到相关国家政府或国际机构执行制裁的国家或地区居民',
    who_am_p_10: '8. 您非限制或禁止开展数字货币相关活动国家或地区的居民，包括但不限于中国大陆地区等',
    who_am_p_11: '9. 您使用本程序提供的服务在您所在的国家或地区符合相关法律法规和政策',
    who_am_p_12: '10. 您同意：如因您所在国家或地区的法律法规和政策或其他任何适用法律的原因，导致您使用本程序的服务违法，您将独立承担相关法律风险和责任，您无条件且不可撤销地放弃向本程序进行追索的权利',
    who_am_p_13: '11. 您应该理解并遵守当地的法律法规。如果您使用此产品，默认代表将接受上述许可证。因本产品引起的法律问题与本产品无关',
    who_am_p_14: '12. 特别感谢:',
    top_panel_chart_title: '矿池算力',
    top_panel_p_1: '当前算力',
    top_panel_content_1: '编辑端口',
    top_panel_content_2: '热更新手续费',
    top_panel_content_3: '停止端口',
    top_panel_content_4: '开启端口',
    top_panel_content_5: '删除端口',
    top_panel_script_1: '端口状态正常',
    top_panel_script_2: '端口已关闭',
    top_panel_script_3: '排队中, 请稍等...',
    top_panel_script_4: '关闭端口',
    top_panel_script_5: '确定要关闭端口吗? 正在此端口下的设备会断开。',
    top_panel_script_6: '提示',
    top_panel_script_7: '此币种算力计算单位未装配, 不影响抽水逻辑及实际运行。',
    top_panel_script_8: 'LTC抽水尽量不要抽到鱼池, 建议跨池抽水至viabtc以获得更精准的算力。',
    top_panel_script_9: '注意, 建议在herominer挖掘alph, 其他矿池存在diff问题, 如果要跑其他池子建议使用nginx纯转发。',
    top_panel_script_10: '请不要使用bzminer挖掘RXD!!!',
    top_panel_script_11: '[BTC] 如果端口内有不支持跨池抽水的设备，设备对应的列表背景颜色将变为黄色，同时系统会自动将此设备强制抽水至代理矿池地址内，通常只有少部分老旧的机型会发生, 如需关闭此功能 请前在 端口设置 - 其他设置- 老旧机型优化 选项关闭。',
    top_panel_script_12: '此币种已开启客户算力保护机制, 为了保护客户算力最小的损耗, 只可以抽到客户损耗临界点, 所以会存在抽少的情况, 如需关闭请在编辑端口内改变此配置。',
    top_panel_script_13: 'NGINX无法抽水, 设备列表中是所有进入的TCP链接, 所有信息均为占位符, 例如设备名称、钱包地址, NGINX无法解析数据, 只能用作纯转发, 除端口协议、目标矿池地址、目标矿池协议外, 其余所有配置均不生效, 可以转发任意币种。',
    top_panel_script_14: '请等待端口响应再进行操作。',
    auth_config_label_1: '强制下发成功份额',
    auth_config_content_1: '如果提交失败, 也强制给矿机返回成功, 这样一来矿机或内核显示的是百分百成功。',
    deal_config_p_1: '端口协议',
    deal_config_p_2: '兼容老KT的本地客户端协议, KENC客户端可直接接入这里。',
    deal_config_p_3: '端口允许混合协议进入, 设备可通过TCP或SSL方式都可进入此端口。',
    deal_config_p_4: 'TCP/TLS双协议',
    default_config_p_1: 'OKMiner优化:',
    default_config_p_2: '开启',
    default_config_p_3: '关闭',
    default_config_p_4: 'mallob地址:',
    default_config_p_5: '算力保护:',
    default_config_p_6: '快速选择',
    default_config_p_7: '等待输入',
    default_config_content_1: '无法连接OKMiner的矿机, 可通过开启此选项进行优化。',
    default_config_content_2: '最大限度保护客户算力损耗, 开启后可能会抽不够, 只在客户发生损耗之前抽到尽量多的算力, 但是客户算力不会损耗。如果关闭, 以抽水精准为目标, 但不对客户算力做任何保护措施, 自行斟酌开关。',
    default_config_place_1: '最大65535',
    default_config_script_1: '请先选择要创建的代理币种。',
    default_config_script_2: '纯转发',
    default_config_script_3: '请注意',
    default_config_script_4: `
        ** 本地挖矿内核请注意版本 **
        <br><br>
        DNX官方已宣布关闭mallob验证, 无需再配置mallob地址, 请使用最新的挖矿内核进行挖矿。
        <br><br>
        虽然已关闭验证, 目前看起来官方并未按照公布时间关闭验证, 请等待矿池以及官方后续通知, 在此之前请不要开启抽水,程序依旧保留了mallob的代理转发, 您的mallob地址为：
        <br><br>
        {mobURL}
        <br><br>
        如果您尚未升级，请确保将您的矿工升级到最新版本（OZM 1.2.5 或 SRB 2.3.9），官方从 10 月 21 日 04:00 开始停止对旧版本的支持（目前看起来并未生效）。感谢您的支持！
        <br><br>
        Nvidia: <a href="https://github.com/OneZeroMiner/onezerominer/releases" target="_blank">OneZeroMiner</a><br>
        AMD: <a href="https://github.com/doktor83/SRBMiner-Multi/releases" target="_blank">RBMiner</a><br> 
        请不要使用DynexSolve内核进行挖掘。
    `,
    default_config_script_5: '注意',
    default_config_script_6: '创建纯转发端口, 此端口只对[端口号], [最大连接数], [端口配置], [代理矿池地址、协议] 这些配置生效, 其余配置均不生效, 不含数据统计及手续费等功能,此端口可以进入任意币种及型号的设备。',
    default_config_script_7: 'LTC抽水请不要抽到鱼池, 最好的选择是跨池抽到viabtc或是其他池子, 跨池抽水不影响客户, 鱼池会严重抽不够, 矿池原因。',
    default_config_script_8: '注意, 建议在herominer挖掘alph, 其他矿池存在diff问题, 如果要跑其他池子建议使用nginx纯转发。',
    default_config_script_9: '请不要使用bzminer挖掘RXD!!!',
    pump_config_p_1: '快速选择',
    pump_config_script_1: '请先选择要创建的代理币种。',
    pump_config_script_2: '我已了解代理矿池与手续费矿池地址不同可能会导致设备无法正常工作',
    pump_config_script_3: '添加失败',
    pump_config_script_4: '最多只能添加十个钱包',
    client_con_label_1: '客户端版本',
    client_con_label_2: '程序运行时长',
    client_con_label_3: '备注',
    client_con_label_4: '算力',
    client_con_label_5: '在线 / 离线',
    client_con_label_6: '全部',
    client_con_label_7: 'CPU / 内存',
    client_con_label_8: '上行 / 下行',
    client_con_label_9: '操作',
    client_con_empty_1: '暂未添加任何客户端。',
    client_con_place_1: '请选择',
    client_con_p_1: '填入其他{CONFIG.TITLE}的API, 在此处可方便统一观察、管理。',
    client_con_p_2: '本机API：',
    client_con_p_3: '刷新API',
    client_con_p_4: '（刷新本机API后, 原有API将失效, 控制端需重新填入被控客户端API）',
    client_con_p_5: '+添加客户端',
    client_con_p_6: '版本',
    client_con_p_7: '程序运行时长',
    client_con_p_8: '备注',
    client_con_p_9: '实时算力',
    client_con_p_10: '在线 / 离线',
    client_con_p_11: 'CPU / 内存',
    client_con_p_12: '上行 / 下行',
    client_con_p_13: '快速查看',
    client_con_p_14: '远程访问',
    client_con_p_15: '编辑',
    client_con_p_16: '删除',
    client_con_script_1: '获取中...',
    client_con_script_2: '获取失败',
    client_con_script_3: '刷新token失败!!!',
    client_con_script_4: '删除此项',
    client_con_script_5: '确定要删除这条吗?',
    client_con_script_6: '删除成功',
    client_con_script_7: '删除失败',
    client_con_script_8: '获取API失败!!!',
    client_con_script_9: '尚未获取完毕, 请稍后刷新。',
    client_con_script_10: '获取列表失败。',
    client_con_script_11: '活跃设备',
    client_con_script_12: '离线设备',
    client_con_script_13: '内存',
    client_con_script_14: 'CPU',
    client_con_script_15: '接收',
    client_con_script_16: '发送',
    login_view_script_1: '是否忘记了密码？',
    login_view_script_2: `
        如果您忘记了密码, Linux可运行安装脚本, 选择重置密码选项, 即可恢复默认账号密码。
        <br><br>
        如果您是Windows, 请结束程序后手工删除程序目录里所有 0. 开头的文件, 再次启动程序即可恢复默认账号密码。
        <br><br>
        默认账号密码：
        <br><br>
        qzpm19kkx xloqslz913
    `,
    login_view_script_3: '信息检查不通过!',
    login_view_script_4: '登陆成功!',
    port_inside_script_1: '请稍等...',
    port_inside_script_2: '打开端口',
    port_inside_script_3: '关闭端口',
    port_inside_script_4: '打开端口',
    port_inside_script_5: '端口关闭成功',
    port_inside_script_6: '端口关闭失败',
    port_inside_script_7: '端口打开成功',
    port_inside_script_8: '端口打开失败',
    port_inside_script_9: '删除端口',
    port_inside_script_10: '确定删除此端口吗?',
    port_inside_script_11: '端口删除成功',
    port_inside_script_12: '端口删除失败',
    system_view_script_1: '检测到正在使用默认账号密码。请一定先修改账号密码。',
    line_chart_3_script_1: '小时平均：',
    set_safe_route: '设置安全访问地址',
    set_safe_p_1: '设置一个只有自己知道的访问地址, 避免被扫描到后台, 以提高安全性, 防止被爆破入侵, 请务必设置。',
    set_safe_p_2: '例：',
    set_safe_p_3: '假如您的后台访问地址为  http://1.2.3.4:3333, 安全地址设置为 nihao, 保存之后后台则只能通过 http://1.2.3.4:3333/nihao/ 才能访问。',
    set_safe_p_4: '设置完毕后程序将自动重启, LINUX非脚本安装用户请注意启动程序, WINDOWS用户设置完毕后请手动启动程序。',
    set_safe_p_5: '务必牢记自己设置的安全地址, 如忘记, LINUX用户请运行安装脚本查看路由, WINDOWS用户请在目录下找到rust-config文件, 其中的ROUTE字段就是自定义地址。',
    set_safe_p_6: '自定义安全地址内不允许包含任意特殊字符, 仅允许英文字符串, 请不要携带（/ // \\ ? ）等特殊符号。',
    set_safe_p_7: '保存安全地址',
    set_safe_p_8: '注意！！！ 设置完毕后访问时, 访问的URL结尾一定要以 / 结尾, 例如 http://a.b.com:1234/fuck/, 否则会访问不到后台。',
    set_safe_label_1: '后台访问地址预览：',
    set_safe_label_2: '自定义安全地址：',
    set_safe_label_3: '尚未设置安全地址, 请在此处输入',
    set_safe_route_script_1: '警告',
    set_safe_route_script_2: '您尚未设置安全的后台地址, 请务必设置安全访问地址, 否则暴漏在公网的后台地址容易被扫描爆破。',
    set_safe_route_script_3: '获取路由失败。',
    set_safe_route_script_4: '不允许携带//',
    set_safe_route_script_5: '不允许以 / 结尾',
    set_safe_route_script_6: '不允许以 / 开头',
    set_safe_route_script_7: '不允许携带 ?',
    set_safe_route_script_8: '确认配置',
    set_safe_route_script_9: `
    <p>后台访问地址即将改变至：</p>
    <br>
    <p>
        <a href="{preview_url}" target="_blank">{preview_url}</a>    
    </p>
    <br>
    <p>请牢记。</p>
    <br>
    <p>确认后程序将重启, LINUX非脚本安装用户请注意启动程序, WINDOWS用户设置完毕后请手动启动程序。</p>
    `,
    set_safe_route_script_10: '设置完毕',
    set_safe_route_script_11: '请等待自动刷新, WINDWOS用户请重新打开程序, 程序启动后手动访问新的地址。',
    auth_config_label_2: '老旧机型优化',
    auth_config_content_2: '对于无法支持抽水的矿机, 抽水时这些矿机会走客户矿池进行抽水, 不需要此功能请关闭它。',
    replace_name_p_1: '指定矿工名称',
    replace_name_p_2: '替换为',
    replace_name_c_1: '此处输入要被替换矿工的名称, 格式为一行一个名称, 可使用 * 来匹配所有矿工',
    replace_name_c_2: '匹配到的矿工将替换为此处的名称',
    d_l_sciprt_1: '设备名已重定向'
}
<template>
    <div class="select-preset">
        <Modal
            class-name="select-preset-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.select_preset_pool_title')"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p style="margin-bottom: 20px;">{{$t('lang.select_preset_pool_p_1')}}</p>
                <el-tabs type="card" class="device-list-tab">
                    <el-tab-pane class="device-list-tab-panel" :label="$t('lang.select_preset_pool_label_1')">
                        <el-table
                            :data="poolInfo"
                            style="width: 100%"
                            :empty-text="$t('lang.select_preset_pool_empty').replace('{selectCoin}', selectCoin)"
                        >
                            <el-table-column
                                prop="name"
                                :label="$t('lang.select_preset_pool_label_2')"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <img class="name-coin" :src="$Func.getCoinImage(`${(scope.row.coin).toLowerCase()}`)" />
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                :label="$t('lang.select_preset_pool_label_3')"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deal"
                                :label="$t('lang.select_preset_pool_label_4')">
                            </el-table-column>
                            <el-table-column :label="$t('lang.select_preset_pool_label_5')">
                                <template slot-scope="scope">
                                    <el-link type="success" @click="selectItem(scope)">
                                        {{$t('lang.select_preset_pool_select')}}
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane class="device-list-tab-panel" :label="$t('lang.select_preset_pool_label_6')">
                        <el-table
                            :data="presetPool"
                            style="width: 100%"
                            :empty-text="$t('lang.select_preset_pool_empty_1').replace('{selectCoin}', selectCoin)"
                        >
                            <el-table-column
                                prop="name"
                                :label="$t('lang.select_preset_pool_label_7')"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <img class="name-coin" :src="$Func.getCoinImage(`${(scope.row.coin).toLowerCase()}`)" />
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                :label="$t('lang.select_preset_pool_label_8')"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deal"
                                :label="$t('lang.select_preset_pool_label_9')">
                            </el-table-column>
                            <el-table-column :label="$t('lang.select_preset_pool_label_10')">
                                <template slot-scope="scope">
                                    <el-link type="success" @click="selectItem(scope)">
                                        {{$t('lang.select_preset_pool_select')}}
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Modal>
    </div>
</template>
<script>

import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            selectCoin: 'BTC',
            endPoint: {},
            presetPool: [
            ],
            callback: null,
            serverPool: [],
            poolInfo: []
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency'
        })
    },
    mounted() {
        
    },
    methods: {
        show(coin, callback) {
            this.visable = true

            coin && (this.selectCoin = coin)
            callback && (this.callback = callback)

            this.getEndPoint()

            this.getPoolInfo()
        },
        getPoolInfo() {
            let that = this;

            this.axios({
                method: 'get',
                url: this.$API.getPoolInfo.replace('{id}', 1)
            }).then(res => {
                if (res && res.status == 200) {
                    if (res.data && res.data.length) {
                        that.serverPool = res.data

                        this.renderList()
                    }
                } else {
                    this.$Message.error(this.$t('lang.select_preset_pool_script_1'))
                }
            })
        },
        hide() {
            this.visable = false
        },
        getEndPoint() {
            let that = this;
            this.loading = true;

            this.axios({
                method: 'get',
                url: that.$API.getEndPoint
            }).then(res => {
                if (res.status == 200) {
                    that.endPoint = res.data

                    this.renderList();
                } else {
                    that.$Message.error(this.$t('lang.select_preset_pool_script_2'));
                }

                this.loading = false
            })
        },
        renderList() {
            let customPool = this.endPoint.customPool;
            let selectCoin = this.selectCoin;
            let serverPool = this.serverPool;

            if (customPool) {
                let presetPool = []

                for (let i = 0; i < customPool.length; i++) {
                    let item = customPool[i];

                    if (item.coin == selectCoin) {
                        presetPool.push(item)
                    }
                }

                this.presetPool = presetPool
            }

            if (serverPool) {
                let poolInfo = [];

                for (let i = 0; i < serverPool.length; i++) {
                    let item = serverPool[i];

                    if (item.brand == selectCoin) {
                        let insert = {
                            coin: item.brand,
                            name: item.content,
                            address: item.url
                        }

                        if (item.protocol == 0) {
                            insert.deal = 'TCP'
                        } else if (item.protocol == 1) {
                            insert.deal = 'SSL'
                        }

                        poolInfo.push(insert)
                    }
                }

                this.poolInfo = poolInfo
            }
        },
        activeCoinChange() {
            this.renderList()
        },
        selectItem(scope) {
            this.callback && this.callback(scope.row)

            this.hide()
        },
        transName(v) {
            let res = v;

            if (v == 'NGINX') {
                res = this.$t('lang.select_preset_pool_script_3')
            } else if (v == 'KAS(ks3)') {
                res = 'KAS(ks3, ks3l, ks3m)'
            } else if (v == 'KASPA') {
                res = 'KAS(GPU)'
            }

            return res
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .select-preset-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .select-preset-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.select-preset-model{
    margin: 0 auto;
    width: 50%;
    max-width: 700px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .el-tabs__item{
        font-size: 12px;
    }

    .pre-name{
        display: flex;
        align-items: center;

        .name-coin{
            height: 20px;
            display: block;
            margin-right: 5px;
        }
    }
}

.select-preset-select-coin-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
        display: block;
        // width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    span{
        font-size: 14px;
    }
    .engine{
        font-size: 12px;
        flex: 1;
        text-align: right;
    }
}
</style>

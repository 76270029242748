<template>
    <div id="app">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
		<event-bus></event-bus>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'
import EventBus from './components/EventBus.vue'

export default {
	name: 'App',
	components: {
		EventBus
	},
	methods: {
		mounted() {
		}
	}
}
</script>

<style lang="scss">
</style>

<template>
    <div class="select-preset-wallet">
        <Modal
            class-name="select-preset-wallet-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.select_perset_wallet_title')"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p style="margin-bottom: 20px;">{{$t('lang.select_perset_wallet_p_1')}}</p>
                <p style="margin-bottom: 20px;">{{$t('lang.select_perset_wallet_p_2')}}</p>
                <el-tabs type="card" class="device-list-tab">
                    <el-tab-pane class="device-list-tab-panel" :label="$t('lang.select_perset_wallet_label_1')">
                        <el-table
                            :data="presetWallet"
                            style="width: 100%"
                            :empty-text="$t('lang.select_perset_wallet_empty')"
                        >
                            <el-table-column
                                prop="name"
                                :label="$t('lang.select_perset_wallet_label_2')"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                :label="$t('lang.select_perset_wallet_label_3')"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column :label="$t('lang.select_perset_wallet_label_4')">
                                <template slot-scope="scope">
                                    <el-link type="success" @click="selectItem(scope)">
                                        {{$t('lang.select_perset_wallet_p_3')}}
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Modal>
    </div>
</template>
<script>

import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            // selectName: '',
            // selectAddress: '',
            endPoint: {},
            presetWallet: [
            ],
            callback: null
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency'
        })
    },
    mounted() {
        
    },
    methods: {
        show(callback) {
            this.visable = true

            callback && (this.callback = callback)

            this.getEndPoint()
        },
        hide() {
            this.visable = false
        },
        selectItem(scope) {
            this.callback && this.callback(scope.row)

            this.hide()
        },
        getEndPoint() {
            let that = this;
            this.loading = true;

            this.axios({
                method: 'get',
                url: that.$API.getEndPoint
            }).then(res => {
                if (res.status == 200) {
                    that.endPoint = res.data

                    this.renderList();
                } else {
                    that.$Message.error(this.$t('lang.select_perset_wallet_error'));
                }

                this.loading = false
            })
        },
        renderList() {
            let customWallet = this.endPoint.customWallet;

            if (!customWallet) {
                return
            }   

            let res = []

            if (customWallet.length) {
                res = customWallet
            }

            this.presetWallet = res
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .select-preset-wallet-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .select-preset-wallet-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.select-preset-wallet-model{
    margin: 0 auto;
    width: 50%;
    max-width: 700px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .el-tabs__item{
        font-size: 12px;
    }

    .pre-name{
        display: flex;
        align-items: center;

        .name-coin{
            height: 20px;
            display: block;
            margin-right: 5px;
        }
    }
}

.select-preset-wallet-select-coin-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
        display: block;
        // width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    span{
        font-size: 14px;
    }
    .engine{
        font-size: 12px;
        flex: 1;
        text-align: right;
    }
}
</style>

<template>
    <div class="wallet-ban">
        <Modal
            class-name="wallet-ban-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.wallet_ban_title')"
            :footer-hide="true"
        >
            <div>
                <p v-show="loading">{{$t('lang.wallet_ban_p_1')}}</p>
                <p v-show="!loading" class="title">{{$t('lang.wallet_ban_p_2')}}</p>
                <div style="margin: 10px 0;">
                    <Input :placeholder="$t('lang.wallet_ban_place')" v-model="currentWallet" size="small">
                        <Button size="small" slot="append" type="primary" @click="addTargetWallet">{{$t('lang.wallet_ban_p_3')}}</Button>
                    </Input>
                </div>
                <div class="wallet-box">
                    <div class="wallet-item" v-for="(item, key) in wallets" :key="key">
                        <div class="wallet" @click="walletOpt(item)">
                            <Button size="large" type="text">{{$t('lang.wallet_ban_p_4')}} {{ item }}</Button>
                        </div>
                        <div class="time">
                            {{$t('lang.wallet_ban_p_5')}}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <wallet-alert ref="wallet-alert"></wallet-alert>
    </div>
</template>
<script>

// import TermCon from '@/components/TermCon.vue'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            wallets: [],
            currentWallet: ''
        }
    },
    components: {
        // TermCon
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        addTargetWallet() {
            if (this.currentWallet) {
                this.axios({
                    method: 'post',
                    url: this.$API.banWallet,
                    data: {
                        ips: [this.currentWallet]
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.$Message.success(this.$t('lang.wallet_ban_script_1'))

                        this.getList()
                    } else {
                        this.$Message.success(this.$t('lang.wallet_ban_script_2'))
                    }
                })
            }
        },
        show() {
            this.visable = true

            this.getList();
        },
        hide() {
            this.visable = false
        },
        getList() {
            this.loading = true
            
            this.axios({
                method: 'get',
                url: this.$API.getBanWallet
            }).then(res => {
                if (res.status == 200) {
                    this.wallets = res.data.reverse()
                } else {
                    this.$Message.error(this.$t('lang.wallet_ban_script_3'))
                }

                this.loading = false
            })
        },
        walletOpt(wallet) {
            let getList = this.getList;

            this.$refs['wallet-alert'].show(wallet, getList)
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .wallet-ban-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .wallet-ban-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.wallet-ban-model{
    margin: 0 auto;
    width: 50%;
    max-width: 1400px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .wallet-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .wallet-item{
            margin-right: 10px;
            margin-bottom: 10px;

            .wallet{
                display: flex;
                justify-content: center;
                font-size: 14px;
            }

            .time{
                text-align: center;
            }
        }
    }
}
</style>

<template>
    <div>
        <proxy-model ref="proxy-model"></proxy-model>
        <set-user-model ref="set-user"></set-user-model>
        <safe-route ref="safe-route"></safe-route>
        <hot-wallet ref="hot-wallet"></hot-wallet>
    </div>
</template>
<script>

import ProxyModel from './ProxyModel.vue'
import SetUserModel from '@/components/Model/SetUserModel.vue'
import SafeRoute from '@/components/Model/SafeRoute.vue'
import HotWallet from '@/components/Model/HotWallet.vue'

export default {
    data() {
        return {
        }
    },
    components: {
        ProxyModel,
        SetUserModel,
        SafeRoute,
        HotWallet
    },
    computed:{
    },
    methods:{
    },
    mounted() {
        // 将频繁复用的组件，放到事件总线里处理, 避免渲染过多重复的组件
        // 例如添加端口, 很多地方在调用, 每个调用的地方都去创建一个公共组件十分浪费
        
        // 端口操作
        this.$EventBus.$on("Proxy.show", (mode, portId, coin) => {
            this.$refs['proxy-model'].show(mode, portId, coin)
        })

        // 修改账号密码
        this.$EventBus.$on("SetUser.show", () => {
            this.$refs['set-user'].show()
        })

        // 安全路由
        this.$EventBus.$on("SafeRoute.show", () => {
            this.$refs['safe-route'].show()
        })

        // 钱包热更新
        this.$EventBus.$on("HotWallet.show", (portData) => {
            this.$refs['hot-wallet'].show(portData)
        })
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="set-kenc-key">
        <Modal
            class-name="set-kenc-key-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.set_kenc_key_title')"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p  style="margin-bottom: 10px;">{{$t('lang.set_kenc_p_1')}}</p>
                <Form ref="formInline" :label-width="80">
                    <Row :gutter="10" type="flex" justify="start">
                        <Col :xs="24" :md="24" :lg="24">
                            <FormItem :label="$t('lang.set_kenc_label_1')">
                                <Input v-model="key0" type="text" :placeholder="$t('lang.set_kenc_place_1')">
                                </Input>
                            </FormItem>
                            <FormItem :label="$t('lang.set_kenc_label_2')">
                                <Input v-model="key1" disabled type="text" :placeholder="$t('lang.set_kenc_place_2')">
                                </Input>
                            </FormItem>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Button :loading="loading" style="margin-bottom: 10px;" @click="save" long type="success">{{$t('lang.set_kenc_p_2')}}</Button>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Button :loading="loading" @click="reset" long type="warning">{{$t('lang.set_kenc_p_3')}}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    </div>
</template>
<script>

// import TermCon from '@/components/TermCon.vue'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            key0: '',
            key1: ''
        }
    },
    components: {
        // TermCon
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        show() {
            this.visable = true
            this.loading = true

            this.axios({
                method: 'get',
                url: this.$API.kencKey
            }).then(res => {
                this.loading = false;

                if (res.status == 200) {
                    this.key0 = res.data
                }
            })
        },
        hide() {
            this.visable = false
        },
        reset() {
            this.loading = true;

            this.axios({
                method: 'post',
                url: this.$API.resetKencKey
            }).then(res => {
                this.loading = false;

                if (res.status == 200) {
                    this.$Message.success(this.$t('lang.set_kenc_script_1'))

                    this.key0 = ''
                    this.key1 = ''

                    this.hide()
                }
            })
        },
        save() {
            this.loading = true;

            this.axios({
                method: 'post',
                url: this.$API.kencKey,
                data: {
                    k: this.key0
                }
            }).then(res => {
                this.loading = false;

                if (res.status == 200) {
                    this.$Message.success(this.$t('lang.set_kenc_script_2'))

                    this.hide()
                }
            })
        }
    },
    watch: {
        key0() {
            if (this.key0) {
                this.key1 = this.$md5(this.key0).slice(8,24)
            }
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .set-kenc-key-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .set-kenc-key-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.set-kenc-key-model{
    margin: 0 auto;
    width: 50%;
    max-width: 1400px;
    overflow: hidden!important;
    overflow-y: auto!important;
}
</style>

<template>
    <Form class="deal-config-form" :model="ProxyData" label-position="top">
        <Row :gutter="25" type="flex" justify="start">
            <Col style="margin-bottom: 10px;" :xs="24" :md="24" :lg="24">
                <div class="deal-ttips">
                    <div>
                        <span class="xx">*</span>
                        {{$t('lang.deal_config_p_1')}}
                    </div>
                    <div>
                        <span v-show="ProxyData.ProxyDeal === 'TCP'">
                            <!-- <span class="deal-intro">[TCP]</span> -->
                            {{ $t('lang.proxy.tcp_intro') }}
                        </span>
                        <span v-show="ProxyData.ProxyDeal === 'SSL'">
                            <!-- <span class="deal-intro">[SSL]</span> -->
                            {{ $t('lang.proxy.ssl_intro') }}
                        </span>
                        <span v-show="ProxyData.ProxyDeal === 'RMS'">
                            <!-- <span class="deal-intro">[RMS]</span> -->
                            {{ $t('lang.proxy.rms_intro') }}
                        </span>
                        <span v-show="ProxyData.ProxyDeal === 'KENC'">
                            <!-- <span class="deal-intro">[KENC]</span> -->
                            {{$t('lang.deal_config_p_2')}}
                        </span>
                        <span v-show="ProxyData.ProxyDeal === 'SOCKS5'">
                            <!-- <span class="deal-intro">[SOCKS5]</span> -->
                        </span>
                        <span v-show="ProxyData.ProxyDeal === 'TTS'">
                            <!-- <span class="deal-intro">[TCP/TLS]</span> -->
                            {{$t('lang.deal_config_p_3')}}
                        </span>
                    </div>
                </div>
            </Col>
        </Row>
        <Row :gutter="25" type="flex" justify="start">
            <Col style="margin-bottom: 24px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('TCP')" @click.native="selectActive('TCP')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item deal-radio" v-model="ProxyData.ProxyDeal" label="TCP">TCP</el-radio>
                            <img src="@/assets/tcp.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 24px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('SSL')" @click.native="selectActive('SSL')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="SSL">SSL/TLS</el-radio>
                            <img src="@/assets/ssl.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 24px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('TTS')" @click.native="selectActive('TTS')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="TTS">{{$t('lang.deal_config_p_4')}}</el-radio>
                            <img src="@/assets/mixin.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 24px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('RMS')" @click.native="selectActive('RMS')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="RMS">RMS</el-radio>
                            <img src="@/assets/rust.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 24px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('KENC')" @click.native="selectActive('KENC')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="KENC">KENC</el-radio>
                            <img src="@/assets/ktlogo.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <!-- <Col style="margin-bottom: 24px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                        <Card style="height:100%;" v-bind:class="classObject('SOCKS5')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" disabled v-model="ProxyData.ProxyDeal" label="SOCKS5">SOCKS5</el-radio>
                            <img src="@/assets/socket.png">
                        </div>
                    </Card>
                </FormItem>
            </Col> -->
        </Row>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'

export default {
    data() {
        return {
            ProxyData: ProxyData.data
        }
    },
    computed: {
        classObject() {
            return (deal) => {
                let result = {
                    active: false
                }

                if (this.ProxyData.ProxyDeal == deal) {
                    result.active = true
                }
                
                return result
            }
        }
    },
    mounted() {
    },
    methods: {
        selectActive(deal) {
            this.ProxyData.ProxyDeal = deal
        }
    }
}
</script>

<style lang="scss">
    .deal-config-form{
        margin-bottom: 0;

        .deal-ttips{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            color: var(--font-color-1);

            .xx{
                font-family: SimSun;
                font-size: 12px;
                color: #ed4014;
                margin-right: 2px;
            }
        }

        .ivu-form-item-content{
            // height: 100%;
            height: 34px;
        }

        .form-item-o{
            // height: 100%;
            margin-bottom: 0;
            // background-color: var(--bg-1);

            .ivu-card-bordered{
                background-color: var(--bg-1);
            }

            .ivu-card-bordered.active{
                background-color: unset;
            }

            .ivu-card-body{
                height: 100%;
                padding: 0 7px;

                .item-card{
                    height: 100%;

                    img{
                        display: block;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .deal-intro{
            color: var(--font-color-1);
            font-weight: bold;
        }

        .deal-radio-item{
            margin: 0;
        }

        .el-radio__label{
            font-size: 12px!important;
            font-weight: unset!important;
            padding-left: 0!important;
            color: var(--color-1)!important;
        }

        .deal-radio-item.is-checked{
            .el-radio__label{
                color: var(--color-3)!important;
                font-weight: bold!important;
            }
        }

        .el-radio__inner{
            display: none;
        }
    }
</style>
<style lang="scss" scoped>

</style>

<template>
    <div class="add-preset-wallet">
        <Modal
            class-name="add-preset-wallet-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.add_perset_wallet_title')"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p style="margin-bottom: 20px;">{{$t('lang.add_perset_wallet_desc')}}</p>
                <Form :label-width="120">
                    <FormItem :label="$t('lang.add_perset_wallet_input_addr_label')">
                        <el-input v-model="selectName" size="small" :placeholder="$t('lang.add_perset_wallet_input_addr_label_desc')"></el-input>
                    </FormItem>
                    <FormItem :label="$t('lang.add_perset_wallet_input_addr')">
                        <el-input v-model="selectAddress" size="small" :placeholder="$t('lang.add_perset_wallet_input_addr_desc')"></el-input>
                    </FormItem>
                    <FormItem label=" ">
                        <Button type="primary" @click="addWallet">{{$t('lang.add_perset_wallet_fast_button')}}</Button>
                    </FormItem>
                </Form>
                <p style="margin-bottom: 20px;">{{$t('lang.add_perset_wallet_pool_preview')}}</p>
                <el-tabs type="card" class="device-list-tab">
                    <el-tab-pane class="device-list-tab-panel" :label="$t('lang.add_perset_wallet_custom_pool')">
                        <el-table
                            :data="presetWallet"
                            style="width: 100%"
                            :empty-text="$t('lang.add_perset_wallet_scripe_1')"
                        >
                            <el-table-column
                                prop="name"
                                :label="$t('lang.add_perset_wallet_name')"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                :label="$t('lang.add_perset_wallet_addr')"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column :label="$t('lang.add_perset_wallet_opt')">
                                <template slot-scope="scope">
                                    <el-link type="warning" @click="deleteItem(scope)">
                                        {{$t('lang.add_perset_wallet_delete')}}
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Modal>
    </div>
</template>
<script>

import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            selectName: '',
            selectAddress: '',
            endPoint: {},
            presetWallet: [
            ]
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency'
        })
    },
    mounted() {
        
    },
    methods: {
        show() {
            this.visable = true

            this.getEndPoint()
        },
        hide() {
            this.visable = false
        },
        addWallet() {
            let that = this;

            if (!this.selectName || !this.selectAddress) {
                this.$Message.warning(this.$t('lang.add_perset_wallet_script_2'))
                return
            }

            let item = {
                name: this.selectName,
                address: this.selectAddress,
            }

            if (!this.endPoint.customWallet) {
                this.endPoint.customWallet = [item]
            } else {
                this.endPoint.customWallet.push(item)
            }

            this.setEndPoint()
        },
        getEndPoint() {
            let that = this;
            this.loading = true;

            this.axios({
                method: 'get',
                url: that.$API.getEndPoint
            }).then(res => {
                if (res.status == 200) {
                    that.endPoint = res.data

                    this.renderList();
                } else {
                    that.$Message.error(this.$t('lang.add_perset_wallet_script_3'));
                }

                this.loading = false
            })
        },
        setEndPoint() {
            let that = this;
            this.loading = true;

            let requestData = this.endPoint;
            
            this.axios({
                method: 'post',
                url: that.$API.setEndPoint,
                data: requestData
            }).then(res => {
                if (res.status == 200) {
                    that.$Message.success(this.$t('lang.add_perset_wallet_script_4'));
                    that.getEndPoint()
                } else {
                    that.$Message.error(this.$t('lang.add_perset_wallet_script_3'));

                    this.loading = false
                }
            })
        },
        renderList() {
            let customWallet = this.endPoint.customWallet;

            if (!customWallet) {
                return
            }   

            let res = []

            if (customWallet.length) {
                res = customWallet
            }

            this.presetWallet = res
        },
        activeCoinChange() {
            this.renderList()
        },
        deleteItem(scope) {
            let index = scope.$index;

            this.presetWallet.splice(index, 1);

            this.endPoint.customWallet = this.presetWallet;

            this.setEndPoint()
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .add-preset-wallet-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .add-preset-wallet-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.add-preset-wallet-model{
    margin: 0 auto;
    width: 50%;
    max-width: 700px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .el-tabs__item{
        font-size: 12px;
    }

    .pre-name{
        display: flex;
        align-items: center;

        .name-coin{
            height: 20px;
            display: block;
            margin-right: 5px;
        }
    }
}

.add-preset-wallet-select-coin-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
        display: block;
        // width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    span{
        font-size: 14px;
    }
    .engine{
        font-size: 12px;
        flex: 1;
        text-align: right;
    }
}
</style>

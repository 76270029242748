<template>
    <div>
        <Modal
            class-name="set-user-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.set_user_title')"
            :footer-hide="true"
        >
            <div class="box">
                <div style="margin-bottom: 10px;">
                    <p v-html="$t('lang.set_user_p_1')"></p>
                </div>
                <Form ref="formInline" inline>
                    <Row :gutter="10" type="flex" justify="start">
                        <Divider orientation="left">{{$t('lang.set_user_p_2')}}</Divider>
                        <Col :xs="24" :md="24" :lg="24">
                            <Input v-model="c_u" style="margin-bottom: 10px;" type="text" :placeholder="$t('lang.set_user_place_1')">
                                <i class="el-icon-user" slot="prepend"></i>
                            </Input>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Input v-model="c_p" style="margin-bottom: 10px;" type="password" :placeholder="$t('lang.set_user_place_2')">
                                <i class="el-icon-lock" slot="prepend"></i>
                            </Input>
                        </Col>
                        <Divider orientation="left">{{$t('lang.set_user_p_3')}}</Divider>
                        <Col :xs="24" :md="24" :lg="24">
                            <Input v-model="n_u" style="margin-bottom: 10px;" type="text" :placeholder="$t('lang.set_user_place_3')">
                                <i class="el-icon-lock" slot="prepend"></i>
                            </Input>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Input v-model="nn_u" style="margin-bottom: 10px;" type="text" :placeholder="$t('lang.set_user_place_4')">
                                <i class="el-icon-lock" slot="prepend"></i>
                            </Input>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Input v-model="n_p" style="margin-bottom: 10px;" type="password" :placeholder="$t('lang.set_user_place_5')">
                                <i class="el-icon-lock" slot="prepend"></i>
                            </Input>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Input v-model="nn_p" style="margin-bottom: 10px;" type="password" :placeholder="$t('lang.set_user_place_6')">
                                <i class="el-icon-lock" slot="prepend"></i>
                            </Input>
                        </Col>
                        <Col :xs="24" :md="24" :lg="24">
                            <Button :loading="loading" @click="save" long type="primary">{{$t('lang.set_user_p_4')}}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            c_u: '',
            c_p: '',
            n_u: '',
            nn_u: '',
            n_p: '',
            nn_p: '',
            loading: false
        }
    },
    components: {
    },
    computed:{
    },
    mounted() {
       
    },
    methods:{
        ...mapActions('port', [
            'updatePorts'
        ]),
        ...mapActions('storage', [
            'updateLocal'
        ]),
        save() {
            let allow = true

            if (!this.c_u || !this.c_p || !this.n_u || !this.nn_u || !this.n_p || !this.nn_p) {
                allow = false
            }

            if (this.n_u !== this.nn_u) {
                allow = false
            }

            if (this.n_p !== this.nn_p) {
                allow = false
            }

            if (!allow) {
                this.$Swal.fire({
                    icon: 'error',
                    title: this.$t('lang.set_user_script_1'),
                    text: this.$t('lang.set_user_script_2')
                })

                return
            }

            this.loading = true

            this.axios({
                url: this.$API.setPassword,
                method: 'post',
                data: {
                    rawpassword: this.c_p,
                    password: this.n_p,
                    repassword: this.nn_p,
                    username: this.n_u
                }
            }).then(res => {
                if (res.status == 200) {
                    this.$Message.success(this.$t('lang.set_user_script_3'));

                    this.updateLocal({
                        'loginfo': `${this.n_u}|${this.nn_p}`
                    })

                    this.updatePorts()
                    this.loading = false
                }
            })
        },
        show() {
            this.visable = true
        },
        hide() {
            this.visable = false
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $hideMenuWidth){
    .set-user-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.set-user-model{
    margin: 0 auto;
    width: 50%;
    max-width: 600px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .ivu-modal-body{
        padding: 0;
    }

    .box{
        padding: 20px;
    }
}
</style>

import axios from 'axios'
import CONFIG from '@/script/config.js'

const $CONFIG = CONFIG.CONFIG

const state = () => ({
    meta: {},
    currency: {},
    sortCurrency: [],
    coinArray: [],
    systemInfo: {},
    originConfig: {},
    startTime: 0,
    localVersion: '0.0.0',
    mobURL: '',
    mobStatus: 0,
    baseInfo: {},
    route: ''
})

const getters = {
    getMeta: (state, getters, rootState) => {
        return state.meta
    },
    getCurrency: (state, getters) => {
        return state.currency
    },
    getSortCurrency: (state, getters) => {
        return state.sortCurrency
    },
    getSystemInfo: (state, getters) => {
        return state.systemInfo
    },
    getLocalVersion: (state, getters) => {
        return state.localVersion
    },
    getStartTime: (state, getters) => {
        return state.startTime
    },
    getOriginConfig: (state, getters) => {
        return state.originConfig
    },
    getMob: (state, getters) => {
        return {
            mobURL: state.mobURL,
            mobStatus: state.mobStatus
        }
    },
    getBaseInfo: (state, getters) => {
        return state.baseInfo
    },
    getRoute: (state, getters) => {
        return state.route
    }
}

const actions = {
    updateRoute ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getRoute
            }).then(res => {
                if (res.status == 200) {
                    commit('setRoute', res.data)

                    resolve(res.data)
                } else {
                    reject(res)
                }
            })
        })
    },
    updateMobURL ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getMob
            }).then(res => {
                if (res && res.status == 200) {
                    commit('setMobURL', res.data.port)
                    commit('setMobStatus', res.data.status)

                    resolve(res)
                } else {
                    reject(res)
                }
            })
        })
    },
    updateLocalVersion ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getLocalVersion
            }).then(res => {
                if (res && res.status == 200) {
                    commit('setLocalVersion', res.data.version)

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateStartTime ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getStartTime
            }).then(res => {
                if (res && res.status == 200) {
                    commit('setStartTime', res.data)

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateOriginConfig ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getOriginConfig
            }).then(res => {
                if (res && res.status == 200) {
                    commit('setOriginConfig', res.data)

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateSystemInfo ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getSystemInfo
            }).then(res => {
                if (res && res.status == 200) {
                    commit('setSystemInfo', res.data)

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateBaseInfo ({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.base_info
            }).then(res => {
                if (res && res.status == 200) {
                    commit('setBaseInfo', res.data)

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateCurrency ({ state, commit }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.getCurrency
            }).then(res => {
                if (res && res.status == 200) {
                    let insert = {}
                    let sortCurrency = []

                    // 整理原始币种数据
                    for (let key in res.data) {
                        if (res.data[key].is_pub === 1) {
                            insert[key] = res.data[key]
                        } else {
                            // 一些币种需要隐藏
                            if (window.localStorage.getItem('debage')) {
                                insert[key] = res.data[key]
                            }
                        }
                    }

                    // 转换为排序数据
                    for (let i = 0; i < $CONFIG.SORT_CURRENCY.length; i++) {
                        let sortTarget = $CONFIG.SORT_CURRENCY[i];

                        if (insert[sortTarget]) {
                            sortCurrency.push(insert[sortTarget])
                        }
                    }

                    // 将没有在顺序配置内的币种添加进去
                    for (let sKey in insert) {
                        if ($CONFIG.SORT_CURRENCY.indexOf(sKey) == -1) {
                            sortCurrency.push(insert[sKey])
                        }
                    }

                    commit('setCurrency', insert);
                    commit('setSortCurrency', sortCurrency)

                    resolve(res);
                } else {
                    reject(res);
                }
            }).catch(e => {
                reject(e);
            })
        })
    }
}

const mutations = {
    setMeta (state, data) {
        state.meta = data
    },
    setCurrency (state, data) {
        state.currency = data
    },
    setSortCurrency (state, data) {
        state.sortCurrency = data
    },
    setSystemInfo (state, data) {
        state.systemInfo = data
    },
    setLocalVersion (state, data) {
        state.localVersion = data
    },
    setStartTime (state, data) {
        state.startTime = data
    },
    setOriginConfig (state, data) {
        state.originConfig = data
    },
    setMobURL (state, data) {
        state.mobURL = window.location.hostname + ':' + data
    },
    setMobStatus(state, data) {
        state.mobStatus = data
    },
    setBaseInfo(state, data) {
        state.baseInfo = data
    },
    setRoute(state, data) {
        state.route = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
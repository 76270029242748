<template>
    <div>
        <Modal
            class-name="hot-wallet-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="$t('lang.hot_wallet_title')"
            :footer-hide="true"
        >
            <div class="box" v-loading="loading">
                <p style="margin-bottom: 20px;">{{ $t('lang.hot_wallet_desc') }}</p>
                <Tabs v-model="activeIndex">
                    <TabPane v-for="(item, index) in wallets" :label="$t('lang.hot_wallet_script_1').replace('{wallet}', (index + 1))" v-bind:key="index" :name="`active${index}`">
                        <Form :label-width="120">
                            <FormItem
                                :label="$t('lang.proxy.wallet_addr')"
                                class="input-btn-group"
                            >
                                <Input 
                                    :placeholder="$t('lang.proxy.wallet_addr')"
                                    v-model="item.addr" 
                                ></Input>
                                <Button type="primary" @click="selectPreWallet">
                                    <i class="el-icon-place"></i>
                                    {{$t('lang.hot_wallet_fast')}}
                                </Button>
                            </FormItem>
                            <FormItem
                                :label="$t('lang.proxy.fee_addr')"
                                class="input-btn-group"
                            >
                                <Input 
                                    :placeholder="$t('lang.proxy.fee_addr')"
                                    v-model="item.pool_address"
                                ></Input>
                                <Button type="primary" @click="selectPreProxy">
                                    <i class="el-icon-place"></i>
                                    {{$t('lang.hot_wallet_fast')}}
                                </Button>
                            </FormItem>
                            <FormItem :label="$t('lang.proxy.fee_deal')">
                                <el-radio v-model="item.pool_protocol" :label="0">TCP</el-radio>
                                <el-radio v-model="item.pool_protocol" :label="1">SSL</el-radio>
                            </FormItem>
                            <FormItem :label="$t('lang.proxy.fee_percent')">
                                <Slider v-model="item.ratio" :step="0.01" :min="0" :max="100" show-input></Slider>
                            </FormItem>
                            <FormItem 
                                :label="$t('lang.proxy.fee_name')"
                            >
                                <Input  
                                    :placeholder="$t('lang.proxy.fee_name_desc')"
                                    v-model="item.device"
                                ></Input>
                            </FormItem>
                            <FormItem>
                                <!-- <Button type="error" long>{{$t('lang.proxy.delete_wallet')}}</Button> -->
                                <el-link type="danger" @click.native="deleteWallet(index)">{{$t('lang.proxy.delete_wallet')}}{{ (index + 1) }}</el-link>
                            </FormItem>
                        </Form>
                    </TabPane>
                    <Button size="small" slot="extra" @click.native="addWallet">+</Button>
                </Tabs>
                <div>
                    <Button @click.native="updateWallets()" style="margin-bottom: 10px;" type="success" long>{{$t('lang.hot_wallet_update')}}</Button>
                    <Button @click.native="hide()" type="default" long>{{ $t('lang.hot_wallet_cancel') }}</Button>
                </div>
            </div>
        </Modal>
        <select-preset-pool ref="select-preset-pool"></select-preset-pool>
        <select-preset-wallet ref="select-preset-wallet"></select-preset-wallet>
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'


export default {
    data() {
        return {
            loading: true,
            visable: false,
            portData: {},
            activeIndex: "active0",
            wallets: [
                {
                    "id": "",
                    "server_id": "",
                    "addr": "",
                    "device": "",
                    "pool_address": "",
                    "pool_protocol": 0,
                    "ratio": 0,
                    "created_at": "",
                    "updated_at": ""
                }
            ]
        }
    },
    components: {
    },
    computed:{
    },
    mounted() {
       
    },
    methods:{
        ...mapActions('port', [
            'updatePorts'
        ]),
        selectPreProxy() {
            let that = this;
            let activeIndex = parseInt(this.activeIndex.split('active')[1])

            if (this.portData.currency) {
                this.$refs['select-preset-pool'].show(this.portData.currency, (row) => {
                    this.wallets[activeIndex].pool_address = row.address
                    if (row.deal == 'TCP') {
                        this.wallets[activeIndex].pool_protocol = 0
                    } else if (row.deal == 'SSL') {
                        this.wallets[activeIndex].pool_protocol = 1
                    }
                }) 
            } else {
                this.$Message.warning(this.$t('lang.hot_wallet_script_2'))
            }
        },
        selectPreWallet() {
            let that = this;
            let activeIndex = parseInt(this.activeIndex.split('active')[1])

            if (this.portData.currency) {
                this.$refs['select-preset-wallet'].show((row) => {
                    this.wallets[activeIndex].addr = row.address
                }) 
            } else {
                this.$Message.warning(this.$t('lang.hot_wallet_script_2'))
            }
        },
        show(portData) {
            this.$notify({
                title: this.$t('lang.hot_wallet_script_3'),
                message: this.$t('lang.hot_wallet_script_4'),
                type: 'warning',
                duration: 10000
            });
            
            this.visable = true
            this.portData = portData

            this.axios({
                url: this.$API.getPortById.replace('{id}', portData.id),
                method: 'get'
            }).then(res => {
                // console.log(res)
                // 服务器数据转化为本地数据
                if (res.status == 200) {
                    if (res.data.wallets.length) {
                        this.wallets = res.data.wallets
                        this.ratioToLocal();
                    } else {
                        this.initWallet()
                    }
                }

                // console.log(this.wallets)
                this.loading = false;
            }).catch(e => {
                this.loading = false;
            })
        },
        hide() {
            this.visable = false
        },
        // 将服务器上的比例, 放大成本地的0-100
        ratioToLocal() {
            this.wallets.forEach(item => {
                item.ratio = item.ratio * 100
            })
        },
        // 将本地的比例, 缩小至服务器范围
        ratioToServer() {
            this.wallets.forEach(item => {
                item.ratio = item.ratio / 100
            })
        },
        createWallet() {
            return {
                "id": "",
                "server_id": "",
                "addr": "",
                "device": "",
                "pool_address": "",
                "pool_protocol": 0,
                "ratio": 0,
                "created_at": "",
                "updated_at": ""
            }
        },
        initWallet() {
            this.wallets = [];
            this.wallets.push(this.createWallet())
        },
        addWallet() {
            this.wallets.push(this.createWallet())

            this.activeIndex = `active${this.wallets.length - 1}`
        },
        deleteWallet(index) {
            let wallet = this.wallets[index];
            
            if (wallet.id) {
                this.loading = true
                
                this.axios.delete(this.$API.deleteWallet.replace('{id}', wallet.id)).then(res => {
                    this.loading = false

                    if (res.status == 200) {
                        this.$Message.success(this.$t('lang.hot_wallet_script_5').replace('{wallet}', (index + 1)));
                        this.wallets.splice(index, 1)
                    } else {
                        this.$Message.error(this.$t('lang.hot_wallet_script_6').replace('{wallet}', (index + 1)));
                    }

                    if (!this.wallets.length) {
                        this.wallets.push(this.createWallet())
                    }

                    this.updatePorts()
                })
            } else {
                this.wallets.splice(index, 1)

                if (!this.wallets.length) {
                    this.wallets.push(this.createWallet())
                }
            }

            this.activeIndex = "active0"
        },
        updateWallets() {
            let check = this.checkWallets();

            if (!check.res) {
                this.$Swal.fire({
                    icon: 'error',
                    title: this.$t('lang.hot_wallet_script_7'),
                    text: check.message
                })
            } else {
                this.ratioToServer();
                
                for (let i = 0; i < this.wallets.length; i++) {
                    ((i) => {
                        let wallet = this.wallets[i];
                        delete wallet.updated_at

                        // 有id就更新
                        if (wallet.id) {
                            this.axios({
                                method: 'post',
                                url: this.$API.updateWallet.replace('{id}', wallet.id),
                                data: wallet
                            }).then(res => {
                                if (res.status == 200) {
                                    this.$Message.success(this.$t('lang.hot_wallet_script_8').replace('{wallet}', (i + 1)));
                                } else {
                                    this.$Message.error(this.$t('lang.hot_wallet_script_9').replace('{wallet}', (i + 1)));
                                }

                                this.updatePorts()
                            })
                        } else {
                            delete wallet.id

                            // 没id就添加
                            this.axios({
                                method: 'post',
                                url: this.$API.addWallet,
                                data: wallet
                            }).then(res => {
                                if (res.status == 200) {
                                    this.$Message.success(this.$t('lang.hot_wallet_script_10').replace('{wallet}', (i + 1)));
                                } else {
                                    this.$Message.error(this.$t('lang.hot_wallet_script_11').replace('{wallet}', (i + 1)));
                                }

                                this.updatePorts()
                            })
                        }
                    })(i)

                    this.hide()
                }
            }
        },
        checkWallets() {
            let res = {
                res: true,
                message: ''
            };

            this.wallets.forEach((item, index) => {
                // 检查的时候顺便把serverid赋值
                if (!item.server_id) {
                    item.server_id = this.portData.id;
                }

                if (!item.addr) {
                    res.res = false;
                    res.message = this.$t('lang.hot_wallet_script_12').replace('{wallet}', (index + 1))
                } else {
                    item.addr = this.$Func.dSpace(item.addr)
                }

                if (!item.device) {
                    res.res = false;
                    res.message = this.$t('lang.hot_wallet_script_13').replace('{wallet}', (index + 1))
                } else {
                    item.device = this.$Func.dSpace(item.device)
                }

                if (!item.pool_address) {
                    res.res = false;
                    res.message = this.$t('lang.hot_wallet_script_14').replace('{wallet}', (index + 1))
                } else {
                    item.pool_address = this.$Func.dSpace(item.pool_address)
                }
            })

            return res;
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $hideMenuWidth){
    .hot-wallet-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.hot-wallet-model{
    margin: 0 auto;
    width: 50%;
    max-width: 600px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .ivu-modal-body{
        padding: 0;
    }

    .box{
        padding: 20px;
    }

    .input-btn-group .ivu-form-item-content{
        display: flex;

        input{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
</style>
